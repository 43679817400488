/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */

import React, { useState, useEffect } from "react";
import SearchSynonymManagement from "../components/SearchSynonymManagement";
import EditSynonymModal from "../components/EditSynonymModal";
import { getSearchSynonyms, updateSearchSynonyms } from "../../../api/searchSettingServices";
import LocaleDropDownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";
import { getTranslationConfig } from "../../../api/translationServices";


const SearchSynonymManagementContainer = () => {
  const [synonymSetting, setSynonymSetting] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [elements, setElements] = useState(null);
  const [field, setField] = useState(null);
  const [addMode, setAddMode] = useState(false);
  const [locale, setLocale] = useState("");


  const getSynonyms = () => {
    const searchConfigurationView = { code: "SearchSynonyms", locale };
    getSearchSynonyms(searchConfigurationView).then((response) => {
      if (response && response.data) {
        const customization = [];
        const { locale } = response.data;
        if (response.data.defaultConfiguration
          && Array.isArray(response.data.defaultConfiguration)) {
          response.data.defaultConfiguration.forEach((siteSetting) => {
            const id = siteSetting.field;
            const name = siteSetting.elements;
            const { active } = siteSetting;
            customization.push({ id, name, active });
          });
        }
        setSynonymSetting(customization);
      }
    });
  };

  useEffect(() => {
    getSynonyms();
  }, [locale]);

  useEffect(() => {
    getTranslationConfig().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        setLocale(data.defaultLocale);
      }
    });
  }, []);

  const updateSynonym = (() => {
    const defaultConfiguration = [];
    synonymSetting.map((item) => {
      const field = item.id;
      const elements = item.name;
      const { active } = item;
      defaultConfiguration.push({ field, elements, active });
    });
    const updateData = { code: "SearchSynonyms", locale, defaultConfiguration };
    updateSearchSynonyms(updateData).then((response) => {
      if (response && response.data) {
        const customization = [];
        const { locale } = response.data;
        if (response.data.defaultConfiguration
          && Array.isArray(response.data.defaultConfiguration)) {
          response.data.defaultConfiguration.forEach((siteSetting) => {
            const id = siteSetting.field;
            const name = siteSetting.elements;
            const { active } = siteSetting;
            customization.push({ id, name, active });
          });
        }
        setSynonymSetting(customization);
      }
    });
  });

  const cancelConfirm = () => {
    setEditMode(false);
  };

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setElements(value);
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const onHandleEditSynonyms = (id) => {
    setField(id);
    synonymSetting.forEach((synonym) => {
      if (id === synonym.id) {
        setElements(synonym.name);
      }
    });
    setEditMode(true);
  };

  const onHandleAddSynonyms = () => {
    if (synonymSetting.length > 0) {
      const lastElement = synonymSetting[synonymSetting.length - 1];
      const id = parseInt(lastElement.id) + 1;
      setField(id);
    } else {
      setField(1);
    }
    setElements("");
    setEditMode(true);
    setAddMode(true);
  };

  const updateStatus = (active, name, id) => {
    synonymSetting.forEach((synonym, index) => {
      if (id === synonym.id) {
        synonym.name = name;
        synonym.active = active;
        synonymSetting.splice(index, 1, synonym);
        setSynonymSetting(synonymSetting);
        setEditMode(false);
      }
      updateSynonym();
    });
  };

  const deleteSynonyms = (id) => {
    synonymSetting.forEach((synonym, index) => {
      if (id === synonym.id) {
        synonymSetting.splice(index, 1);
        setSynonymSetting(synonymSetting);
      }
    });
    updateSynonym();
  };

  const handleConfirm = () => {
    if (addMode) {
      synonymSetting.push({ id: field, name: [elements], active: true });
      setAddMode(false);
      updateSynonym();
    } else {
      synonymSetting.forEach((synonym, index) => {
        if (field === synonym.id) {
          synonym.name = [elements];
          synonymSetting.splice(index, 1, synonym);
          setSynonymSetting(synonymSetting);
          setEditMode(false);
        }
        updateSynonym();
      });
    }
    setAddMode(false);
    setEditMode(false);
  };

  const localeOnChange = (localeLang, isDefault, translatableProperties) => {
    setLocale(localeLang);
  };

  return (
    <>
      <SearchSynonymManagement
        updateSynonym={updateSynonym}
        synonymSetting={synonymSetting}
        onHandleEditSynonyms={onHandleEditSynonyms}
        addNewSynonym={onHandleAddSynonyms}
        onHandleDeleteSynonym={deleteSynonyms}
        updateStatus={updateStatus}
        LocaleDropDownContainer={LocaleDropDownContainer}
        localeOnChange={localeOnChange}
      />

      <EditSynonymModal
        isOpen={Boolean(editMode)}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={handleConfirm}
        field={field}
        elements={elements}
        handleFormChange={handleFormChange}
      />
    </>
  );
};

export default SearchSynonymManagementContainer;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

const constants = {
  DATE_FORMAT: "DD/MM/YYYY",
  ORDER_MANAGMENT_ORDER_LISTING_SORT_BY_ORDER_DATE: "orderDate",
  NAVIGATION_MENU_POSITIONS: {
    WEB_HEADER: "WebHeader",
    WEB_FOOTER: "WebFooter",
    WEB_RESPONSIVE_HEADER: "WebResponsiveHeader",
    WEB_RESPONSIVE_FOOTER: "WebResponsiveFooter",
  },
  NAVIGATION_ITEM_TYPES: {
    category: "Category", product: "Product", brand: "Brand", custom: "Custom", system: "System",
  },
  CAMPAIGN_EXPERIENCE: { promotion: "promotion" },
  PROMOTION: {
    CLASS_ORDER: "Order",
    CONTEXT_ORDER: "Order",
    CLASS_SHIPPING: "Shipping",
    CONTEXT_SHIPPING: "Shipping",
    CLASS_PRODUCT: "Product",
    CONTEXT_PRODUCT: "Product",
    CONTEXT_PRODUCT_OPTION: "ProductOption",
    DISCOUNT_AMOUNT: "AmountPromotion",
    DISCOUNT_PRODUCTS: "QuantityPromotion",
    BUY_X_GET_Y_PROMOTION: "BuyXGetYPromotion",
    BUY_X_PAY_Y_PROMOTION: "BuyXPayYPromotion",
    BUY_X_OR_MORE_PAY_Y_PROMOTION: "BuyXOrMorePayYPromotion",
    PRODUCT_OPTION_PROMOTION: "ProductOptionPromotion",
    BONUS_OPTION_PROMOTION: "BonusProductPromotion",
    BUNDLE_PROMOTION: "BundlePromotion",
    DISCOUNT_BUY: "BuyOneGetOne",
    PERCENTAGE_OFF: "PercentageOff",
    FLAT_AMOUNT_OFF: "FlatAmountOff",
    FIXED_AMOUNT: "FixedAmount",
    BUY_X_PAY_Y: "BuyXPayY",
    BUY_X_GET_Y_FREE: "BuyXGetYFree",
    BUY_X_FIXED_AMOUNT: "BuyXFixedAmount",
    FREE_SHIPPING: "FreeShipping",
    FREE_ITEM: "FreeItem",
    SHEET_CONTEXT_PRODUCT: "Product",
    SHEET_CONTEXT_CATEGORY: "Category",
    SHEET_CONTEXT_BRAND: "Brand",
    SHEET_CONTEXT_BUNDLE: "Bundle",
    FILE_TYPES_PROMOTION: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    BULK_UPLOAD_LIMIT: 50,
  },
  STATUS_SUCCESS: "SUCCESS",
  STATUS_INPROGRESS: "INPROGRESS",
  STATUS_WARNING: "WARNING",
  STATUS_FAILED: "FAILED",
  STATUS_INCOMPLETE: "Incomplete",
  STATUS_MERGED: "Merged",
  STATUS_ABANDONED: "Abandoned",
  ORGANIZATIONS: {
    ROOT: "Root",
    RETAILER: "Retailer",
    BRAND: "Brand",
    LOCATION: "Location",
    CHANNELGROUP: "ChannelGroup",
    CHANNEL: "Channel",
  },
  CAROUSAL_LIST: ["CAROUSAL_BANNER", "CAROUSAL_BANNER_TWO", "CAROUSAL_BANNER_THREE"],
  PRODUCT_OPTIONS: {
    WARRANTY: "Warranty",
    INSTALLATION: "Installation",
    GIFTWRAP: "GiftWrap",
  },
  CUSTOMER_GROUP: {
    BIG_SPENDER: "BigSpender",
    EMPLOYEE: "Employee",
    EXACT_MATCH: "exactMatch",
    CONTAINS: "contains",
    FILE_TYPES_CUSTOMER_GROUP: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
  },
  LOCALE : "en_HK"
};

export default constants;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import constants from "./constants";
import commonUtils from "./commonUtils";

const { isListNotEmpty } = commonUtils;
const {
  PROMOTION: {
    CLASS_ORDER, CLASS_PRODUCT, CLASS_SHIPPING,
    CONTEXT_ORDER, CONTEXT_PRODUCT, CONTEXT_SHIPPING, CONTEXT_PRODUCT_OPTION,
    DISCOUNT_AMOUNT, BUY_X_GET_Y_PROMOTION, BUY_X_PAY_Y_PROMOTION, BUY_X_OR_MORE_PAY_Y_PROMOTION,
    PRODUCT_OPTION_PROMOTION, BONUS_OPTION_PROMOTION, BUNDLE_PROMOTION,
    SHEET_CONTEXT_BUNDLE, SHEET_CONTEXT_PRODUCT, SHEET_CONTEXT_CATEGORY, SHEET_CONTEXT_BRAND,
    BULK_UPLOAD_LIMIT,
  },
} = constants;

const promotiionContextMapper = {
  [CLASS_ORDER]: {
    [DISCOUNT_AMOUNT]: CONTEXT_ORDER,
  },
  [CLASS_PRODUCT]: {
    [DISCOUNT_AMOUNT]: CONTEXT_PRODUCT,
    [BUY_X_GET_Y_PROMOTION]: CONTEXT_ORDER,
    [BUY_X_PAY_Y_PROMOTION]: CONTEXT_ORDER,
    [BUY_X_OR_MORE_PAY_Y_PROMOTION]: CONTEXT_ORDER,
    [BONUS_OPTION_PROMOTION]: CONTEXT_ORDER,
    [PRODUCT_OPTION_PROMOTION]: CONTEXT_PRODUCT_OPTION,
    [BUNDLE_PROMOTION]: CONTEXT_ORDER,
  },
  [CLASS_SHIPPING]: {
    [DISCOUNT_AMOUNT]: CONTEXT_SHIPPING,
  },

};

const getPromotionContext = (classType, discountType) => {
  const classTypeMap = promotiionContextMapper[classType] || "";
  const context = classTypeMap[discountType];
  return context;
};


const parseDataFromSheet = (rawData, context) => {
  let headerKeys = ["id"];
  if (context === SHEET_CONTEXT_BUNDLE) {
    headerKeys = ["id", "quantity"];
  }
  if (isListNotEmpty(rawData)) {
    const isValid = rawData.every((data) => headerKeys.every((each) => Object.keys(data).includes(each)));
    if (isValid) {
      const mappedValues = rawData.map((each) => each.id);
      const filteredValues = mappedValues.filter((each, index) => mappedValues.indexOf(each) === index);
      if (filteredValues.length < BULK_UPLOAD_LIMIT) {
        return {
          isValid,
          options: rawData,
          value: filteredValues,
        };
      }
      return {
        isValid: false,
        message: `The maximum number of item theth can be uploaded has exceeded.Please upload the items in batches of ${BULK_UPLOAD_LIMIT}`,
      };
    }
    return {
      isValid,
      message: "Invalid sheet format. Please refer the template for valid data format.",
    };
  }
  return {
    isValid: false,
    message: "No Data Found",

  };
};

const sheetTemplateMapper = {
  [SHEET_CONTEXT_BUNDLE]: [
    { id: "<Product ID 1>", quantity: "<Quantity 1>" },
    { id: "<Product ID 2>", quantity: "<Quantity 2>" },
    { id: "<Product ID 3>", quantity: "<Quantity 3>" },
    { id: "<Product ID 4>", quantity: "<Quantity 4>" },
    { id: "<Product ID 5>", quantity: "<Quantity 5>" },
  ],
  [SHEET_CONTEXT_PRODUCT]: [
    { id: "<Product ID 1>" },
    { id: "<Product ID 2>" },
    { id: "<Product ID 3>" },
    { id: "<Product ID 4>" },
    { id: "<Product ID 5>" },
  ],
  [SHEET_CONTEXT_BRAND]: [
    { id: "<Brand ID 1>" },
    { id: "<Brand ID 2>" },
    { id: "<Brand ID 3>" },
    { id: "<Brand ID 4>" },
    { id: "<Brand ID 5>" },
  ],
  [SHEET_CONTEXT_CATEGORY]: [
    { id: "<Category ID 1>" },
    { id: "<Category ID 2>" },
    { id: "<Category ID 3>" },
    { id: "<Category ID 4>" },
    { id: "<Category ID 5>" },
  ],
};

const getImportSheetTemplate = (context) => sheetTemplateMapper[context];

export default {
  getPromotionContext,
  parseDataFromSheet,
  getImportSheetTemplate,
};

/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */
import Label from "../../../common/core/Label";

/** ========= CUSTOM COMPONENTS ========= */
import ImageCropper from "../../../common/components/imageCropper/Cropper";

/** ========= API SERVICE FUNCTIONS ========= */
import { uploadImage } from "../../../api/pageContentServices";

/** ========= ICON COMPONENT ========= */
import Add from "../../../common/assets/images/svg/add-24px.svg";

/** ========= MODULE STYLES ========= */
import styles from "../css/ImageSelection.module.css";

//* as of now, sample component only */
const ImageSelection = ({
  images,
  handleChangeFile,
  modalOpen,
  imageUrl,
  setModalOpen,
  handleUpload,
  isUploading,
  setIsUploading,
  handleCloseModal,
  handleRemoveImage,
  isSingle,
  aspectRatio,
  fileSelector,
  acceptedFormat,
}) => {
  /** ========== setting fixed image height ============ */
  const fixedHeightPercent = 5;
  /** ========== getting window height */
  const { innerHeight: windowHeight } = window;
  const previewImageHeight = Math.floor(windowHeight / fixedHeightPercent);
  const windowAspects = {
    height: previewImageHeight,
    width: Math.floor(previewImageHeight * aspectRatio),
  };

  /**
   * This method is used to return image url form array
   * @param {Array} media
   */
  const getPreviewImage = (media) => {
    let imgUrl = null;
    /** change preview image key based on api response */
    if (media.largeMediaUrl) {
      imgUrl = media.largeMediaUrl;
    }
    return imgUrl;
  };

  /**
   * This method is used to generate key
   *
   * @param {*} index
   */
  const generateKey = (index) => `id_${index}`;

  const getImageSelector = () => (
    <Label className="btn btn-default w-100 h-100 p-0 m-0">
      <div style={windowAspects}>
        <img
          className="img-fluid img-thumbnail h-100 w-100"
          role="presentation"
          src={Add}
          alt=""
        />
      </div>
      <input
        type="file"
        hidden
        onChange={(e) => handleChangeFile(e)}
        accept={acceptedFormat || ".jpg, .jpeg, .png, .webp, .svg"}
        ref={fileSelector}
      />
    </Label>
  );

  return (
    <div className="d-flex flex-wrap">
      {
        Array.isArray(images) && images.map((image, index) => (
          <div key={generateKey(index)} className={clsx(styles.uploadImagePreview, "m-1 h-auto")} style={{ ...windowAspects, minWidth: windowAspects.width }}>
            <span
              className={styles.uploadImagePreviewClose}
              role="presentation"
              onClick={() => handleRemoveImage(index)}
            >
              x

            </span>
            <img className="img-fluid img-thumbnail" src={getPreviewImage(image)} alt="" />
            {
              isSingle && (
                <div className={styles.hoverImageUpload}>
                  { getImageSelector() }
                </div>
              )
            }
          </div>
        ))
      }
      {
        (!isSingle || (Array.isArray(images) && images.length < 1))
      && (
      <div className={clsx(styles.uploadImagePreviewIcon, "m-1")} style={{ ...windowAspects, minWidth: windowAspects.width }}>
        {
          getImageSelector()
        }
      </div>
      )
    }
      <ImageCropper
        imageUrl={imageUrl}
        modalOpen={modalOpen}
        aspect={aspectRatio}
        setModalOpen={setModalOpen}
        handleUpload={handleUpload}
        imageUploadApi={uploadImage}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

ImageSelection.defaultProps = {
  fileSelector: null,
  acceptedFormat: "",
};

ImageSelection.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  handleUpload: PropTypes.func.isRequired,
  handleChangeFile: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  setIsUploading: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleRemoveImage: PropTypes.func.isRequired,
  isSingle: PropTypes.bool.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  acceptedFormat: PropTypes.string,
  fileSelector: PropTypes.objectOf(PropTypes.any),
};

export default ImageSelection;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Aardra S\n */\n\n .AddURLRedirects_borderRadius2__ISM6A {\n  border-radius: 0.125rem;\n}\n\n.AddURLRedirects_secondaryColor__1rai3 {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.AddURLRedirects_primaryColor__26ocM {\n  background: #e91e63 !important;\n  border-color: #e91e63 !important;\n}\n\n.AddURLRedirects_btn1__osklM {\n  min-width: 6rem;\n}\n\n.AddURLRedirects_btn2__1ZU-F {\n  min-width: 9rem;\n}\n", ""]);
// Exports
exports.locals = {
	"borderRadius2": "AddURLRedirects_borderRadius2__ISM6A",
	"secondaryColor": "AddURLRedirects_secondaryColor__1rai3",
	"primaryColor": "AddURLRedirects_primaryColor__26ocM",
	"btn1": "AddURLRedirects_btn1__osklM",
	"btn2": "AddURLRedirects_btn2__1ZU-F"
};
module.exports = exports;

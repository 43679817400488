/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Jithendra R K
 */
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/BatchTypeReport.module.css";
import Form from "../../../common/core/Form";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Select from "../../../common/components/Select";
import Button from "../../../common/core/Button";
import RefreshIcon from "../../../common/assets/images/svg/refresh.svg";
import publishIcon from "../../../common/assets/images/svg/publish.svg";
import Spinner from "../../../common/core/Spinner";
import CustomizationTable from "../../../common/components/Table";


const BatchTypeReport = ({
  reportType,
  exportBatchTypeReport,
  isExporting,
  onHandleReportTypeChange,
  jobHistoryData,
  schedulerDetails,
  reportTypeList,
}) => {
  const { t } = useTranslation(["common", "batch-type-report"]);
  const getReportName = (name) => reportTypeList.find((data) => data.value === name).label;
  const customRows = [
    {
      label: "Report Name",
      value: "Report Name",
      path: "render",
      render: (row) => {
        if (row && row.name) {
          return (
            <div>{getReportName(row.name)}</div>
          );
        }
      },
      transform: "",
    },
    {
      label: "Status",
      value: "status",
      path: "status",
      transform: "",
    },
    {
      label: "As on Date",
      value: "endDate",
      path: "endDate",
      transform: "",
      type: "date",
    },
    {
      label: "Actions",
      value: "",
      path: "render",
      render: (row) => {
        if (row && row.attributes && row.attributes.exportedFileUrl) {
          return (
            <a href={row.attributes.exportedFileUrl} className="btn btn-sm btn-info text-white">
              Download
            </a>
          );
        }
        return (
          <button type="button" className="btn btn-sm btn-info text-white" disabled>
            Download
          </button>
        );
      },
      transform: "",
    }];


  return (
    <>
      <Row className={clsx("common-page-card pageHeader")}>
        <Col xs="8">
          <span className="pageText">
            {" "}
            Batch Type Reports
          </span>
        </Col>
      </Row>
      <Row className={clsx("common-page-card")}>
        <Col xs="12" className="p-0">
          <Row className="m-0 ">
            <Col xs="12" md="4" className="p-3">
              <Label className="formTextLabel mb-2">
                {t("common:label_report")}
              </Label>
              <Form inline>
                <FormGroup>
                  <Select
                    className="formTextLabel"
                    placeholder="Select Type"
                    name="type"
                    value={reportType}
                    onChange={(e) => onHandleReportTypeChange(e)}
                    options={[
                      ...[
                        {
                          value: "",
                          label: "Select Type",
                        },
                      ],
                      ...(Array.isArray(reportTypeList) ? reportTypeList : []),
                    ]}
                  />
                  <Button
                    onClick={exportBatchTypeReport}
                    className="text-uppercase text-truncate btn-tertiary ml-2"
                  >
                    {t("common:button_export")}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row className="m-0 ">
            <Col xs="6" sm="12" className="mt-5">
              {isExporting
                ? (
                  <div className="formTextLabel" align="center" valign="center">
                    <Spinner size="sm" />
                    <img src={publishIcon} alt="loading" />
                    {t("batch-type-report:alert_download_started")}
                  </div>
                )
                : (schedulerDetails.type === "productDataDumpExportJob"
                  ? (
                    <div className="custom-table">
                      <CustomizationTable
                        customRows={customRows}
                        customizationData={jobHistoryData}
                        isStatusEnable={false}
                        isDeleteEnable={false}
                        isEditEnable={false}
                        isSerialNoEnabled
                      />
                    </div>
                  ) : "")}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default BatchTypeReport;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ChromePicker } from "react-color";
import ProductSelector from "../../productManagement/containers/SimpleProductSelectorContainer";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";

/** ========= MODULE STYLES ========= */
import styles from "../css/BadgeForm.module.css";

const BadgeForm = (props) => {
  const {
    handleFormChange,
    handleChange,
    validator,
    productDetails,
    excludedProducts,
    products,
  } = props;

  const badges = {
    fontColor: "#24292E",
    bgColor: "#fffb00",
  };
  const [fontColor, setFontColor] = useState(badges.fontColor);
  const [isFontOpen, setIsFontOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(badges.bgColor);
  const [isBackgroundOpen, setIsBackgroundOpen] = useState(false);

  const handleChangeFontColor = (data) => {
    if (data && data.hex && data.hex !== fontColor) {
      setFontColor(data.hex);
    }
  };
  const handleChangeBackgroundColor = (data) => {
    if (data && data.hex && data.hex !== backgroundColor) {
      setBackgroundColor(data.hex);
    }
  };

  const toggleFontColorPicker = () => setIsFontOpen(!isFontOpen);
  const toggleBackgroundColorPicker = () => {
    setIsBackgroundOpen(!isBackgroundOpen);
  };

  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  /** internationalization using i18n from common and badge  */
  const { t } = useTranslation(["common", "badge"]);
  const { register, errors } = validator;
  return (
    <>
      <Row>
        <Col xs="12">
          {/*  ====== BADGE FORM ============ */}
          <Row
            className={clsx(styles.blockWrapper, " common-page-card", "pb-0")}
          >
            <Col xs="12" lg="10">
              <div className="common-page-card">
                {/* <Row>
                       <Col xs="12" sm="6">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("badge:label_badge_retailer_id")}
                          </Label>
                          <span className="pl-1 text-danger">*</span>
                          <Input
                            className="formText"
                            placeholder={t("badge:placeholder_code")}
                            name="badge_id"
                            onChange={handleFormChange}
                            innerRef={register({
                              required: "Please enter a badge code",
                              minLength: {
                                value: 3,
                                message: "Please enter a badge code",
                              },
                            })}
                            invalid={!!(errors.badge_id && errors.badge_id.message)}
                          />
                          <FormFeedback>
                            {errors.badge_id && errors.badge_id.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("badge:label_badge_type")}
                          </Label>
                          <Input
                            className="formText"
                            placeholder={t("badge:placeholder_type")}
                            name="badge_type"
                            onChange={handleFormChange}
                           />
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* 2 */}
                <Row>
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label className="formTextLabel">
                        {t("badge:label_badge_primary_text")}
                      </Label>
                      <span className="pl-1 text-danger">*</span>
                      <Input
                        className="formText"
                        placeholder={t("badge:placeholder_primary_text")}
                        name="primary_text"
                        onChange={handleFormChange}
                        innerRef={register({
                          required: "Please enter a primary text",
                          minLength: {
                            value: 3,
                            message: "Please enter a primary text",
                          },
                        })}
                        invalid={
                          !!(errors.primary_text && errors.primary_text.message)
                        }
                      />
                      <FormFeedback>
                        {errors.primary_text && errors.primary_text.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label className="formTextLabel">
                        {t("badge:label_badge_secondary_text")}
                      </Label>
                      <Input
                        className="formText"
                        placeholder={t("badge:placeholder_secondary_text")}
                        name="secondary_text"
                        onChange={handleFormChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6">
                    <FormGroup className={clsx(styles.inputTag)}>
                      <Label className="formTextLabel">
                        {/* {t("badge:label_badge_font_color")} */}
                        Font Color
                      </Label>
                      <span className="pl-1 text-danger">*</span>
                      <Input
                        placeholder={t("badge:placeholder_code")}
                        name="font_color"
                        value={fontColor}
                        onClick={toggleFontColorPicker}
                        onChange={handleFormChange}
                        innerRef={register({
                          required: "Please enter a badge code",
                          minLength: {
                            value: 3,
                            message: "Please enter a badge code",
                          },
                        })}
                        invalid={!!(errors.code && errors.code.message)}
                      />
                      <FormFeedback>
                        {errors.code && errors.code.message}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label className="formTextLabel">
                        {t("badge:label_badge_background_color")}
                      </Label>
                      <Input
                        placeholder={t("badge:placeholder_code")}
                        name="background_color"
                        value={backgroundColor}
                        onClick={toggleBackgroundColorPicker}
                        onChange={handleFormChange}
                        innerRef={register({
                          required: "Please enter a badge code",
                          minLength: {
                            value: 3,
                            message: "Please enter a badge code",
                          },
                        })}
                        invalid={!!(errors.code && errors.code.message)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6">
                    {isFontOpen ? (
                      <div style={popover}>
                        <div style={cover} onClick={toggleFontColorPicker} />
                        <ChromePicker
                          color={fontColor}
                          className={clsx(styles.colorPicker)}
                          onChange={handleChangeFontColor}
                        />
                      </div>
                    ) : (
                        ""
                      )}
                  </Col>
                  <Col xs="12" sm="6">
                    {isBackgroundOpen ? (
                      <div style={popover}>
                        <div
                          style={cover}
                          onClick={toggleBackgroundColorPicker}
                        />
                        <ChromePicker
                          color={backgroundColor}
                          className={clsx(styles.colorPicker)}
                          onChange={handleChangeBackgroundColor}
                        />
                      </div>
                    ) : (
                        ""
                      )}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label className="formTextLabel">Selected products</Label>
                      <ProductSelector
                        name="products"
                        options={
                          Array.isArray(productDetails) ? productDetails : []
                        }
                        value={Array.isArray(products) ? products : []}
                        onChange={(o) => handleChange("products", o)}
                        excluded={
                          Array.isArray(excludedProducts)
                            ? excludedProducts
                            : []
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
BadgeForm.propTypes = {
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  primary_text: PropTypes.string.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  productDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  fontColor: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};
export default BadgeForm;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */


import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Modal from "../../../common/core/Modal";
import ModalBody from "../../../common/core/ModalBody";
import ModalFooter from "../../../common/core/ModalFooter";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Card from "../../../common/core/Card";
import CardBody from "../../../common/core/CardBody";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Form from "../../../common/core/Form";
/** ========= SUB COMPONENT ========= */
import CustomerGroupsContainer from "../containers/CustomerGroupsContainer";
import CouponsContainer from "../containers/CouponsContainer";

/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
/** ============ SVG IMAGE ICON ===================== */

/** ========= MODULE STYLES ========= */
import styles from "../css/CampaignCreatorView.module.css";

const CampaignStepTwoView = (props) => {
  const {
    onSubmitting, campaignId,
    handleChange, handleFormChange, formSubmitHandler, formBackHandler, formSaveDraftHandler, customerGroups, coupons,
    handleCustomerGroups, handleCoupons, translatableFileds,
  } = props;

  /** internationalization using i18n from common and campaign  */
  const { t } = useTranslation(["common", "campaign"]);
  const [customerGroupFlag, setCustomerGroupFlag] = useState(false);
  const [couponFlag, setCouponFlag] = useState(false);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [isCouponOpen, setIsCouponOpen] = useState(false);

  /** For closing the collapse in CustomerGroupsContainer and  CouponsContainer */
  const closeCollapse = () => {
    if (customerGroupFlag) {
      setOpen(!open);
      setModal(false);
      setCustomerGroupFlag(false);
    } else {
      setIsCouponOpen(!isCouponOpen);
      setModal(false);
      setCouponFlag(false);
    }
  };

  /** This method is used for opening and closing the modal */
  const modalOpen = (trigger) => {
    if (customerGroupFlag || couponFlag) {
      setModal(true);
    } else {
      setModal(false);
      if (trigger) {
        setIsCouponOpen(!isCouponOpen);
      } else {
        setOpen(!open);
      }
    }
  };
  return (
    <Row className="justify-content-center text-body">
      <Col sm="12" md="10" xl="8">
        <div className="text-center">
          <h4 className="text-body font-weight-bolder">{t("campaign:title_qualifiers")}</h4>
        </div>
        <div className="text-center">
          <p className="font-weight-normal pt-2 pb-1">
            {t("campaign:sub_title_qualifiers")}
          </p>
        </div>
        <div>
          <div>
            <fieldset disabled={onSubmitting}>
              <div>
                <CustomerGroupsContainer
                  campaignId={campaignId}
                  onChange={handleCustomerGroups}
                  selectedCustomerGroupDetails={customerGroups}
                  setCustomerGroupFlag={setCustomerGroupFlag}
                  modalOpen={modalOpen}
                  customerGroupFlag={customerGroupFlag}
                  open={open}
                  setOpen={setOpen}
                />
              </div>
              <div className="pt-3">
                <CouponsContainer
                  campaignId={campaignId}
                  onChange={handleCoupons}
                  selectedCouponDetails={coupons}
                  setCouponFlag={setCouponFlag}
                  modalOpen={modalOpen}
                  couponFlag={couponFlag}
                  open={isCouponOpen}
                  setOpen={setIsCouponOpen}
                />
              </div>
              <div className="pt-3">
                <Card>
                  <CardBody className="p-3">
                    <FormGroup className="mb-0">
                      <div className="d-flex flex-wrap justify-content-end">
                        <div className="mr-md-auto py-1">
                          <Button onClick={formBackHandler} type="button" className={clsx(styles.borderRadius2, styles.btn, "text-truncate", "mr-1", "button-font-size")}>
                            {t("common:button_back")}
                          </Button>
                        </div>
                        <div className="py-1">
                          <Button onClick={formSaveDraftHandler} type="button" className={clsx(styles.borderRadius2, styles.btn, "text-truncate", "mr-1", "button-font-size")}>
                            {t("campaign:button_save_draft")}
                          </Button>
                        </div>
                        <div className="py-1">
                          <Button type="submit" onClick={formSubmitHandler} color="primary" disabled={couponFlag || customerGroupFlag} className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "button-font-size")}>
                            {t("campaign:button_save_continue")}
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </div>
            </fieldset>
          </div>
        </div>
      </Col>
      <Modal isOpen={modal} modalOpen={modalOpen}>
        <ModalBody>
          {couponFlag ? t("campaign:modal_create_coupon_alert") : t("campaign:modal_create_customer_group_alert")}

        </ModalBody>
        <ModalFooter>
          {" "}
          <Button color="primary" onClick={() => { closeCollapse(); }}>{t("campaign:modal_button_ok")}</Button>
          <Button color="secondary" onClick={() => { setModal(false); }}>{t("campaign:modal_button_close")}</Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

CampaignStepTwoView.defaultProps = {
  campaignId: null,
};

CampaignStepTwoView.propTypes = {
  onSubmitting: PropTypes.bool.isRequired,
  campaignId: PropTypes.string,
  customerGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  coupons: PropTypes.arrayOf(PropTypes.any).isRequired,
  // functions
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  formSubmitHandler: PropTypes.func.isRequired,
  formBackHandler: PropTypes.func.isRequired,
  formSaveDraftHandler: PropTypes.func.isRequired,
  handleCustomerGroups: PropTypes.func.isRequired,
  handleCoupons: PropTypes.func.isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CampaignStepTwoView;

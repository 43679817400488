/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Static Content Creator Container
 *
 * @author Vishnu Raj
 *
 */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import imageUtils from "../../../common/utils/imageUtils";
import { uploadImage } from "../../../api/pageContentServices";
import StaticContentCreator from "../components/StaticContentCreatorView";
import { addStaticContent } from "../../../api/staticContentService";


/** ========= CUSTOM COMPONENTS ========= */
import validateForm from "../../../common/utils/validateForm";
import UploadModal from "../components/UploadModal";

/** ========= UTIlS ========= */
import constants from "../../../common/utils/constants";

const StaticContentCreateConatiner = () => {
  const validator = validateForm();
  const history = useHistory();
  const [media, setMedia] = useState({
    mimeType: "",
    largeMediaUrl: "",
    defaultMediaUrl: "",
    smallMediaUrl: "",
    mediaThumbnailUrl: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [formContents, setFormContent] = useState({
    title: "",
    name: "",
    code: "",
    description: ""
  });
  const [submitting, setSubmitting] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [content, setContent] = useState("");
  const [active, setActive] = useState(false);

  const handleChange = (key, e) => {
    setFormContent({ ...formContents, [key]: e.target.value });
  }

  const handleSubmit = async () => {
    setSubmitting(true);
    const data = {
      locale: constants.LOCALE,
      code: formContents.code,
      type: formContents.name,
      content: {
        title: formContents.title,
        description: formContents.description,
        media: [{
          ...media
        }]
      },
      active: active
    }
    const response = await addStaticContent(data);
    if (response && response.success) {
      redirectToStaticContentPage();
    }
    setSubmitting(false);
  };

  const redirectToStaticContentPage = () => {
    history.push("/merchandising/static-content");
  };

  /**
  * This method is used to handle change file
  *
  * @param {*} event
  */
  const handleChangeFile = async (event, type) => {
    const file = event.target.files[0];
    if (file && file.size > 5242880) {
      alert("Image size must under 5mb!");
    } else {
      const mimeType = file.type
      setMedia({ ...media, mimeType })
      const url = await imageUtils.readURL(file);
      handleUploadImage();
      await uploadImage(file).then((response) => {
        if (response && response.success) {
          const largeMediaUrl = response.data.largeMediaUrl
          setMedia({ ...media, largeMediaUrl })
          setContent("Uploading Completed")
        } else {
          setContent("Something went wrong in the upload!");
        }
      });
    }
  };

  const handleStatusChange = (status) => {
    setActive(status)
  }

  /**
   * This method is used to handle upload image
   *
   * @param {*} url
   */
  const handleUploadImage = () => {
    setContent("Uploading Image...")
    setOpenUploadModal(true)
  };

  const cancelConfirm = () => {
    setOpenUploadModal(false);
  };


  /**
  * This method is used to remove an image
  *
  * @param {*} 
  */
  const handleRemoveImg = () => {
    setMedia({ ...media, largeMediaUrl: "" });
  };

  return (
    <>
      <StaticContentCreator
        handleSubmit={handleSubmit}
        handleCancel={redirectToStaticContentPage}
        handleChange={handleChange}
        handleChangeFile={handleChangeFile}
        imageUrl={media.largeMediaUrl}
        modalOpen={modalOpen}
        media={media}
        setModalOpen={setModalOpen}
        handleUpload={handleUploadImage}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        handleRemoveImage={handleRemoveImg}
        validator={validator}
        formContents={formContents}
        submitting={submitting}
        active={active}
        handleStatusChange={handleStatusChange}
      />
      <UploadModal
        isOpen={openUploadModal}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        content={content}
      />
    </>
  );
};

export default StaticContentCreateConatiner;
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import React from "react";
import { useHistory } from "react-router-dom";

/** ======== CUSTOM COMPONENTS ========= */
import URLRedirectsManagement from "../components/URLRedirectsManagement";

const URLRedirectsManagementContainer = () => {
  const history = useHistory();

  /**
   * This method is used to load add new URL page
   */
  const addNewURL = () => {
    history.push("/marketing/url-redirects/new");
  };

  return <URLRedirectsManagement addNewURL={addNewURL} />;
};

export default URLRedirectsManagementContainer;

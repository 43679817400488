/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/** ========== CUSTOM COMPONENT ================ */
import ProductTypeSelector from "../components/ProductTypeSelector";

/** ===== API SERVICE FUNCTIONS =========== */
import { getProductOptionTypes } from "../../../api/productManagementServices";


const ProductTypeSelectorContainer = ({ name, value, onChange, disabled }) => {
  /** local states */
  const [productTypes, setProductTypes] = useState();

  useEffect(() => {
    /** use to get product type by default */
    getProductOptionTypes().then((response) => {
      if (response && response.success) {
        setProductTypes(response.data);
      }
    });

  }, []);

  return (
    <ProductTypeSelector types={productTypes} selectedProductType={value} onChange={onChange} name={name} disabled={disabled}/>
  );
};

ProductTypeSelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

ProductTypeSelectorContainer.defaultProps = {
  name: "productType",
};

export default ProductTypeSelectorContainer;

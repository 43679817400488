/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * HtmlTextEditor
 *
 * @author Neeraj Raj
 */

import React from "react";
import PropTypes from "prop-types";
/** ========= SUN-EDITOR COMPONENTS ========= */
import SunEditor from "suneditor-react";

const HtmlTextEditor = (props) => {
  const {
    handleChange, content, disabled, isEnableCodeView,
  } = props;

  return (
    <div className="text-editor">
      <SunEditor
        onChange={handleChange}
        setContents={content || ""}
        placeholder="Please type here..."
        setOptions={{
          buttonList: [
            ["undo", "redo"],
            ["bold"],
            ["underline"],
            ["hiliteColor"],
            ["fontColor"],
            isEnableCodeView ? ["fullScreen", "codeView", "preview"] : ["fullScreen", "preview"],
          ],
        }}
        disable={disabled}
      />
    </div>
  );
};

HtmlTextEditor.propTypes = {
  handleChange: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isEnableCodeView: PropTypes.bool.isRequired,
};
export default HtmlTextEditor;


/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import React from "react";
import { useHistory } from "react-router-dom";

/** ======== CUSTOM COMPONENTS ========= */
import AddURLRedirects from "../components/AddURLRedirects";

const AddURLRedirectsContainer = () => {
  const history = useHistory();

  /**
   * This method is used to cancel add new URL form
   */
  const cancelForm = () => {
    history.push("/marketing/url-redirects");
  };

  return <AddURLRedirects cancelForm={cancelForm} />;
};

export default AddURLRedirectsContainer;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * CustomerGroup Management Container
 *
 * @author Indrajith C
 *
 */


import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CustomerGroupManagement from "../components/CustomerGroupManagement";
import { getCustomerGroups, setStatus, deleteUserGroup } from "../../../api/customerGroupServices";

const CustomerGroupManagementContainer = () => {
  const history = useHistory();
  const addNewCustomerGroup = () => {
    history.push("/marketing/customergroups/new");
  };

  /**
   * This method is used to redirect to edit customer group page
   * @param {String} couponId
   */
  const editCustomerGroup = (id) => {
    history.push(`/marketing/customergroups/edit/${id}`);
  };

  const [customerGroups, setCustomerGroups] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const paginationLimit = 5;

  const [deleteCustomerGroup, setDeleteCustomerGroup] = useState(null);

  /**
   * This method is used to cancel confirm form
  */
  const cancelConfirm = () => {
    setDeleteCustomerGroup(null);
  };

  const isFunction = (functionToCheck) => functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";

  /**  This method used to change the customer group status
  * @param {String} id
  * @param {Boolean} status
  */
  const changeCustomerGroupStatus = async (status, id) => {
    if (submitting) return;
    setSubmitting(true);
    const response = await setStatus(id);
    if (response && response.success) {
      const newCustomerGroups = customerGroups.map((customerGroup) => (customerGroup.id === id
        ? ({ ...customerGroup, active: status }) : customerGroup));
      setCustomerGroups(newCustomerGroups);
    }
    setSubmitting(false);
  };


  /**
 * This method is used to list customer Groups
 * @param {String} searchTerm
 * @param {Number} newPage
 * @param {Number} paginationLimit
 * @param {String} sortBy
 * @param {String} sortDirection
 */
  const getCustomerGroupList = (searchTerm = "", newPage = 0, sortBy = "createdDate", sortDirection = "DESC") => {
    getCustomerGroups(searchTerm, newPage, paginationLimit, sortBy, sortDirection).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data) && data.length > 0) {
          setCustomerGroups(data);
          setHasMoreData(true);
          setPage(Math.max(newPage, 0));
        } else {
          setCustomerGroups([]);
          setHasMoreData(false);
        }
      }
    });
  };

  /**
   * method for delete confirm
   */
  const confirmDelete = async () => {
    if (Boolean(deleteCustomerGroup) && deleteCustomerGroup.id) {
      const { id } = deleteCustomerGroup;
      const response = await deleteUserGroup(id);
      if (response && response.success === true) {
        getCustomerGroupList(searchTerm, 0);
      }
      if (isFunction(deleteCustomerGroup.callback)) { deleteCustomerGroup.callback(); }
      setDeleteCustomerGroup(null);
    }
  };

  /**
   * This method is used to trigger pagination
   * @param {Number} page
   */
  const getPageData = (newPage) => {
    getCustomerGroupList(searchTerm, Math.max(newPage, 0));
  };

  /**
   * This method is used to update text in search box
   * @param {Event} event
   */
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) { getCustomerGroupList(value); } else getCustomerGroupList();
  };


  /** getting list of customer Groups when page load */
  useEffect(() => {
    getCustomerGroupList("", 0);
  }, [paginationLimit]);

  return (
    <>
      <CustomerGroupManagement
        addNewCustomerGroup={addNewCustomerGroup}
        customerGroups={customerGroups}
        submitting={submitting}
        getPageData={getPageData}
        page={page}
        hasMoreData={hasMoreData}
        searchTerm={searchTerm}
        handleSearchTerm={handleSearchTerm}
        changeCustomerGroupStatus={changeCustomerGroupStatus}
        editCustomerGroup={editCustomerGroup}
        setDeleteCustomerGroup={setDeleteCustomerGroup}
      />
      <ConfirmationModal
        isOpen={Boolean(deleteCustomerGroup)}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to delete this customer group ?"
      />
    </>
  );
};
export default CustomerGroupManagementContainer;

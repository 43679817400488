/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React, { useState, useEffect } from "react";
import SearchIndexManagement from "../components/SearchIndexManagement";
import { bulkIndexProducts } from "../../../api/productManagementServices";
import { indexCategories } from "../../../api/categoryManagementServices";
import { getSitePreviewConfigurations, bulkIndexingLive } from "../../../api/sitePreviewConfigurationsServices";

const SearchIndexManagementContainer = () => {
  const [rowsLoading, setLoading] = useState([]);
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [indexingMode, setIndexingMode] = useState("");
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  const IndexingModeList = [
    { label: "Staging", value: "STAGING" },
    { label: "Live", value: "LIVE" },
  ];

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setIndexingMode("STAGING");
        }
      }
    });
  }, []);

  const handleIndexingStaging = (row) => {
    if (row.name === "Products") {
      setLoading([row.name, ...rowsLoading]);
      bulkIndexProducts().then((response) => {
        setLoading(rowsLoading.filter((item) => item.name !== row.name));
        if (response && response.data) {
          console.log(response.data);
        }
      });
    }
  };

  const handleIndexingLive = (row) => {
    setLoading([row.name, ...rowsLoading]);
    bulkIndexingLive().then((response) => {
      setLoading(rowsLoading.filter((item) => item.name !== row.name));
      if (response && response.success) {
        setAlert({ type: "success", message: "Search Indexing Successful" });
      }
    });
  };

  const onIndexingModeChange = (e) => {
    const { target: { value } } = e;
    setIndexingMode(value);
  };

  const handleIndexingMethod = previewEnabled && indexingMode === "LIVE"
    ? handleIndexingLive
    : handleIndexingStaging;

  return (
    <SearchIndexManagement
      handleIndexing={handleIndexingMethod}
      owsLoading={rowsLoading}
      IndexingModeList={IndexingModeList}
      indexingMode={indexingMode}
      onIndexingModeChange={onIndexingModeChange}
      previewEnabled={previewEnabled}
      message={message}
    />
  );
};

export default SearchIndexManagementContainer;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */
import httpApi from "./httpApi";

/**
 *This method is used to get staticContent by code
 *
 * @param {*} code
 */
const getStaticContentByCode = async (code) => {
  const response = await httpApi.get(`/v1/static-content/code/${code}`);
  if (response && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 * This method is used to delete a staticContent
 * @param {String} code
 */
const deleteStaticContent = async (code) => {
  const response = await httpApi.delete(`v1/static-content/code/${code}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method used to update a staticContent
 * @param {Object} data
 */
const updateStaticContent = async (data) => {
  const response = await httpApi.patch(`v1/static-content`, data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method used to get all staticContent
 * 
 */
const getAllStaticContent = async () => {
  const response = await httpApi.get(`/v1/static-content/all`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to add new staticContent
 * @param {Object} data
 */
const addStaticContent = async (data) => {
  const response = await httpApi.post(`v1/static-content`, data);
  if (response && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to get staticContent by Id
 *
 * @param {*} id
 */
const getStaticContentById = async (id, locale) => {
  const params = { locale };
  const response = await httpApi.get(`/v1/static-content/id/${id}`, { params });
  if (response && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};





export {
  getStaticContentByCode,
  deleteStaticContent,
  updateStaticContent,
  getStaticContentById,
  addStaticContent,
  getAllStaticContent
};

/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Custom Popup Container
 *
 * @author Vishnu Raj
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import StaticContent from "../components/StaticContent";

/** ========= CUSTOM COMPONENTS ========= */
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import AlertModal from "../../../features/promotionsManagement/components/AlertModal";

/** ========= API SERVICE FUNCTIONS ========= */
import { getAllStaticContent, deleteStaticContent, updateStaticContent } from "../../../api/staticContentService";

const StaticContentContainer = () => {
  const history = useHistory();

  const [staticConstentList, setStaticContentList] = useState("");
  const [selectedContentId, setSelectedContentId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [active, setActive] = useState("");

  const [modalAttibutes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
  });

  useEffect(() => {
    getStaticContent();
  }, []);

  const getStaticContent = () => {
    getAllStaticContent().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setStaticContentList(data)
      }
    });
  }

  const updateStaticContentbyId = async (status, content) => {
    const data = {
      ...content,
      active: status
    }
    if (submitting) return;
    setSubmitting(true);
    const response = await updateStaticContent(data);
    if (response && response.success) {
      getStaticContent();
    } else {
      setModalAttributes({
        isOpen: true,
        content: "Something went wrong.",
        header: "Error",
      });
    }
    setSubmitting(false);
    setSelectedContentId(null);
  }

  const removeStaticContent = (code) => {
    setSelectedContentId(code)
    setOpenConfirmModal(true);
  }

  /**
  * This method is used to cancel confirm form
  */
  const cancelConfirm = () => {
    setSelectedContentId("");
    setOpenConfirmModal(false);
  };

  /**
  * This function form delete promotion
  */
  const confirmDelete = async () => {
    if (submitting) return;
    const response = await deleteStaticContent(selectedContentId);
    if (response && response.success) {
      getStaticContent();
    } else {
      setModalAttributes({
        isOpen: true,
        content: "Something went wrong.",
        header: "Error",
      });
    }
    setSelectedContentId("");
    cancelConfirm();
  };


  const editStaticContent = (saticContentId) => {
    history.push(`/merchandising/static-content/edit/${saticContentId}`);

  };

  const AddNewStaticContent = () => {
    history.push("/merchandising/static-content/new");
  }

  return (
    <>
      <StaticContent
        staticConstentList={staticConstentList}
        AddNewStaticContent={AddNewStaticContent}
        handleStaticContentStatus={updateStaticContentbyId}
        submitting={submitting}
        selectedContentId={selectedContentId}
        active={active}
        editStaticContent={editStaticContent}
        removeStaticContent={removeStaticContent}
      />
      <ConfirmationModal
        isOpen={openConfirmModal}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to delete this static content ?"
      />
      <AlertModal
        isOpen={modalAttibutes.isOpen}
        content={modalAttibutes.content}
        header={modalAttibutes.header}
        togglClose={() => setModalAttributes({
          isOpen: false,
          content: "",
          header: "",
        })}
      />
    </>
  );
}

export default StaticContentContainer;
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Label from "../../../common/core/Label";
import FormGroup from "../../../common/core/FormGroup";
import Button from "../../../common/core/Button";
import CardBody from "../../../common/core/CardBody";
import Input from "../../../common/core/Input";
import Collapse from "../../../common/core/Collapse";
import validateForm from "../../../common/utils/validateForm";
import Form from "../../../common/core/Form";
import validationConstants from "../../../common/utils/validationConstants";
import FormFeedback from "../../../common/core/FormFeedback";

import styles from "../css/CartManagement.module.css";
import RightArrow from "../../../common/assets/images/svg/right-arrow.svg";
import toggleOnIcon from "../../../common/assets/images/svg/toggle-on.svg";
import toggleOffIcon from "../../../common/assets/images/svg/toggle-off.svg";

const CartManagement = ({
  cartSettings,
  handleFormChange,
  saveCartSettings,
  fetchCartSettings,
  isOpen,
  setIsOpen,
  abandonCartOpen,
  setAdandonCartOpen,
  handleChange,
  getReplicationStatus,
  pushToLiveButtonEnabled,
  setAllowChangeMaxItemsCount,
  allowMaximumItemsCount,
  setAllowMinCartAmount,
  allowMinCartAmount,
}) => {
  const toggleUrl = () => setIsOpen(!isOpen);
  const validator = validateForm();
  const { register, errors, handleSubmit } = validator;
  const { t } = useTranslation(["common", "globalSettings"]);
  const { IS_NUMBER } = validationConstants;
  const toggleAbandonCart = () => setAdandonCartOpen(!abandonCartOpen);

  return (
    <>
      <Form onSubmit={handleSubmit(saveCartSettings)}>
        <Row className="pageHeader common-page-card">
          <Col xs="12" md="7" className="text-truncate">
            <span className="pageText">Cart Settings</span>
          </Col>
          <Col xs="12" md="5" className="d-flex justify-content-end">
            <Button
              type="button"
              onClick={() => {
                fetchCartSettings();
                setIsOpen(false);
              }}
              color="secondary"
              className={clsx(
                styles.borderRadius2,
                styles.btn,
                styles.secondaryColor,
                "text-truncate",
                "mr-1"
              )}
            >
              {t("common:button_cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              className={clsx(
                styles.borderRadius2,
                styles.btn,
                styles.primaryColor,
                "text-truncate",
                "mr-1",
                "button-font-size"
              )}
            >
              {t("common:button_save")}
            </Button>
          </Col>
        </Row>
        <Row className={clsx(styles.block2, "common-page-card")}>
          <Col xs="12">
            <div
              className="d-flex align-items-center m-0 text-truncate flex-shrink-1 pr-3"
              onClick={toggleUrl}
              onKeyDown={toggleUrl}
              role="button"
              tabIndex="0"
            >
              <span className=" fontsizeMediumBold text-truncate m-0 pr-3">
                Cart
              </span>
              <span>
                <img
                  src={RightArrow}
                  className={
                    isOpen ? styles.closeCollapse : styles.openCollapse
                  }
                  alt=""
                />
              </span>
            </div>
            <hr />
            <Collapse isOpen={isOpen}>
              <CardBody>
                <Col xs="12">
                  <Row>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <Label className="formTextLabel">
                          {t("globalSettings:label_limit_max_amount")}
                        </Label>
                        <Input
                          className="formText"
                          name="maxAllowedCartAmount"
                          type="number"
                          value={
                            cartSettings && cartSettings.maxAllowedCartAmount
                          }
                          onChange={handleFormChange}
                          innerRef={register({
                            required: t("globalSettings:validation_required"),
                            min: {
                              value: 1,
                              message: t("globalSettings:validation_amount"),
                            },
                            pattern: {
                              value: IS_NUMBER,
                              message: t("common:validation_is_number"),
                            },
                          })}
                          invalid={
                            !!(
                              errors.maxAllowedCartAmount &&
                              errors.maxAllowedCartAmount.message
                            )
                          }
                        />
                        <FormFeedback>
                          {errors.maxAllowedCartAmount &&
                            errors.maxAllowedCartAmount.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <Label className="formTextLabel">
                          {t("globalSettings:label_limit_max_items_in_cart")}
                        </Label>
                        <img
                          src={
                            allowMaximumItemsCount
                              ? toggleOnIcon
                              : toggleOffIcon
                          }
                          className="maxQuantitySwitch"
                          width="23px"
                          alt="drag indiactor icon"
                          onClick={() => setAllowChangeMaxItemsCount()}
                          role="presentation"
                        />
                        <Input
                          className="formText"
                          name="maxAllowedItemsCount"
                          type="number"
                          value={
                            cartSettings && cartSettings.maxAllowedItemsCount
                          }
                          onChange={handleFormChange}
                          disabled={!allowMaximumItemsCount}
                          innerRef={register({
                            required: t("globalSettings:validation_required"),
                            min: {
                              value: 1,
                              message: t("globalSettings:validation_number"),
                            },
                            pattern: {
                              value: IS_NUMBER,
                              message: t("common:validation_is_number"),
                            },
                          })}
                          invalid={
                            !!(
                              errors.maxAllowedItemsCount &&
                              errors.maxAllowedItemsCount.message
                            )
                          }
                        />
                        <FormFeedback>
                          {errors.maxAllowedItemsCount &&
                            errors.maxAllowedItemsCount.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <Label className="formTextLabel">
                          {t(
                            "globalSettings:label_limit_max__promotion_appiled_items_in_cart"
                          )}
                        </Label>
                        <Input
                          className="formText"
                          name="maxAllowedPromotionAppliedItemsCount"
                          type="number"
                          value={
                            cartSettings &&
                            cartSettings.maxAllowedPromotionAppliedItemsCount
                          }
                          onChange={handleFormChange}
                          innerRef={register({
                            required: t("globalSettings:validation_required"),
                            min: {
                              value: 1,
                              message: t("globalSettings:validation_number"),
                            },
                            pattern: {
                              value: IS_NUMBER,
                              message: t("common:validation_is_number"),
                            },
                          })}
                          invalid={
                            !!(
                              errors.maxAllowedPromotionAppliedItemsCount &&
                              errors.maxAllowedPromotionAppliedItemsCount
                                .message
                            )
                          }
                        />
                        <FormFeedback>
                          {errors.maxAllowedPromotionAppliedItemsCount &&
                            errors.maxAllowedPromotionAppliedItemsCount.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <Label className="formTextLabel">
                          {t("globalSettings:label_limit_min_amount")}
                        </Label>
                        <img
                          src={
                            allowMinCartAmount ? toggleOnIcon : toggleOffIcon
                          }
                          className="maxQuantitySwitch"
                          width="23px"
                          alt="drag indiactor icon"
                          onClick={() => setAllowMinCartAmount()}
                          role="presentation"
                        />
                        <Input
                          className="formText"
                          name="minAllowedCartAmount"
                          type="number"
                          value={
                            cartSettings && cartSettings.minAllowedCartAmount
                          }
                          onChange={handleFormChange}
                          disabled={!allowMinCartAmount}
                          innerRef={register({
                            required: t("globalSettings:validation_required"),
                            min: {
                              value: 1,
                              message: t("globalSettings:validation_amount"),
                            },
                            pattern: {
                              value: IS_NUMBER,
                              message: t("common:validation_is_number"),
                            },
                          })}
                          invalid={
                            !!(
                              errors.minAllowedCartAmount &&
                              errors.minAllowedCartAmount.message
                            )
                          }
                        />
                        <FormFeedback>
                          {errors.minAllowedCartAmount &&
                            errors.minAllowedCartAmount.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </CardBody>
            </Collapse>
          </Col>
        </Row>
        <Row className={clsx(styles.block2, "common-page-card")}>
          <Col xs="12">
            <div
              className="d-flex align-items-center m-0 text-truncate flex-shrink-1 pr-3"
              onClick={toggleAbandonCart}
              onKeyDown={toggleAbandonCart}
              role="button"
              tabIndex="0"
            >
              <span className=" fontsizeMediumBold text-truncate m-0 pr-3">
                Abandoned Cart
              </span>
              <span>
                <img
                  src={RightArrow}
                  className={
                    isOpen ? styles.closeCollapse : styles.openCollapse
                  }
                  alt=""
                />
              </span>
            </div>
            <hr />
            <Collapse isOpen={abandonCartOpen}>
              <CardBody>
                <Col xs="12">
                  <Row>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <Label className="formTextLabel">
                          {t(
                            "globalSettings:label_days_cart_items_retained_logged_in"
                          )}
                        </Label>
                        <Input
                          className="formText"
                          name="cartValidity"
                          type="number"
                          value={cartSettings && cartSettings.cartValidity}
                          onChange={handleFormChange}
                          innerRef={register({
                            required: t("globalSettings:validation_required"),
                            min: {
                              value: 1,
                              message: t("globalSettings:validation_number"),
                            },
                            pattern: {
                              value: IS_NUMBER,
                              message: t("common:validation_is_number"),
                            },
                          })}
                          invalid={
                            !!(
                              errors.cartValidity && errors.cartValidity.message
                            )
                          }
                        />
                        <FormFeedback>
                          {errors.cartValidity && errors.cartValidity.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <Label className="formTextLabel">
                          {t(
                            "globalSettings:label_days_cart_items_retained_guest"
                          )}
                        </Label>
                        <Input
                          className="formText"
                          name="anonymousCartValidity"
                          type="number"
                          value={
                            cartSettings && cartSettings.anonymousCartValidity
                          }
                          onChange={handleFormChange}
                          innerRef={register({
                            required: t("globalSettings:validation_required"),
                            min: {
                              value: 1,
                              message: t("globalSettings:validation_number"),
                            },
                            pattern: {
                              value: IS_NUMBER,
                              message: t("common:validation_is_number"),
                            },
                          })}
                          invalid={
                            !!(
                              errors.anonymousCartValidity &&
                              errors.anonymousCartValidity.message
                            )
                          }
                        />
                        <FormFeedback>
                          {errors.anonymousCartValidity &&
                            errors.anonymousCartValidity.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs="6" sm="5" lg="4">
                  <FormGroup
                    className={clsx(styles.noLabelFormGroup, "d-flex")}
                  >
                    <Label className="formTextLabel">
                      Notification Enabled
                    </Label>
                    <div
                      className={clsx(
                        "custom-control",
                        "custom-switch",
                        "ml-3"
                      )}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="notificationEnabled"
                        name="notificationEnabled"
                        checked={
                          cartSettings && cartSettings.notificationEnabled
                        }
                        onChange={() =>
                          handleChange(
                            "notificationEnabled",
                            !cartSettings.notificationEnabled
                          )
                        }
                        disabled
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="notificationEnabled"
                      />
                    </div>
                  </FormGroup>
                </Col>
                {cartSettings && cartSettings.notificationEnabled && (
                  <>
                    <Col xs="6" sm="5" lg="4">
                      <FormGroup
                        className={clsx(styles.noLabelFormGroup, "d-flex")}
                      >
                        <Label className="formTextLabel">
                          Item Level Revival
                        </Label>
                        <div
                          className={clsx(
                            "custom-control",
                            "custom-switch",
                            "ml-3"
                          )}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="itemLevelRevival"
                            name="itemLevelRevival"
                            checked={
                              cartSettings && cartSettings.itemLevelRevival
                            }
                            onChange={() =>
                              handleChange(
                                "itemLevelRevival",
                                !cartSettings.itemLevelRevival
                              )
                            }
                          />
                          <Label
                            className="custom-control-label"
                            htmlFor="itemLevelRevival"
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs="6" sm="5" lg="4">
                      <FormGroup
                        className={clsx(styles.noLabelFormGroup, "d-flex")}
                      >
                        <Label className="formTextLabel">
                          Order Level Revival
                        </Label>
                        <div
                          className={clsx(
                            "custom-control",
                            "custom-switch",
                            "ml-3"
                          )}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="orderLevelRevival"
                            name="orderLevelRevival"
                            checked={
                              cartSettings && cartSettings.orderLevelRevival
                            }
                            onChange={() =>
                              handleChange(
                                "orderLevelRevival",
                                !cartSettings.orderLevelRevival
                              )
                            }
                          />
                          <Label
                            className="custom-control-label"
                            htmlFor="orderLevelRevival"
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                )}
              </CardBody>
            </Collapse>
          </Col>
        </Row>
      </Form>
    </>
  );
};
CartManagement.propTypes = {
  cartSettings: PropTypes.objectOf(PropTypes.any).isRequired,
  handleFormChange: PropTypes.func.isRequired,
  saveCartSettings: PropTypes.func.isRequired,
  fetchCartSettings: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
  setAllowMinCartAmount: PropTypes.number.isRequired,
  allowMinCartAmount: PropTypes.bool.isRequired,
  abandonCartOpen: PropTypes.bool.isRequired,
  setAdandonCartOpen: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setAllowChangeMaxItemsCount: PropTypes.func.isRequired,
  allowMaximumItemsCount: PropTypes.number.isRequired,
};

export default CartManagement;

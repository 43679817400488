/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */

import clsx from "clsx";
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datetime";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";

/** ===== IMAGE ICONS =========== */

/** ========= CUSTOM COMPONENTS ========= */
import ProductTypeSelector from "../containers/ProductTypeSelectorContainer";

/** ======== MODULE STYLES ========== */
import styles from "../css/ProductOptionForm.module.css";
import constants from "../../../common/utils/constants";

const { DATE_FORMAT } = constants;

const ProductForm = (props) => {
  const { name, productValue, priceType, price, handleFormChange } = props;

  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);

  return (
    <>
      <Row className={styles.block3}>
        <Col lg="10">
          <Row>
            <Col xs="7" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("common:label_name")}
                </Label>
                <Input name="priceOptionName" value={name} disabled />
              </FormGroup>
            </Col>
            <Col xs="7" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("common:label_value")}
                </Label>
                <Input
                  className="formText"
                  placeholder=""
                  name="productValue"
                  value={productValue}
                  onChange={(o) => handleFormChange("productValue", o)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="7" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("common:label_price_type")}
                </Label>
                <ProductTypeSelector
                  name="priceType"
                  value={priceType}
                  onChange={handleFormChange}
                />
              </FormGroup>
            </Col>
            <Col xs="7" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("common:label_price")}
                </Label>
                <Input
                  className="formText"
                  placeholder=""
                  name="rate"
                  value={price}
                  min="0"
                  onChange={(o) => handleFormChange("rate", o)}
                  type="number"
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

ProductForm.propTypes = {
  /** form data */
  name: PropTypes.string.isRequired,
  enableProduct: PropTypes.bool.isRequired,
  priceOptionType: PropTypes.string.isRequired,
  priceOptionName: PropTypes.string.isRequired,
  priceOptionValue: PropTypes.string.isRequired,
  priceType: PropTypes.string.isRequired,
  productPrice: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // validation
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductForm;

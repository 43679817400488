/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Mohammed Shefeeq
 */
import httpApi from "./httpApi";

const getOrganizationCountryCode = async () => {
  const response = await httpApi.get("/v1/organization/country");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getLocationOrganizationDetails = async () => {
  const response = await httpApi.get("/v1/organization/location/details");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getOrganizationCountryCode,
  getLocationOrganizationDetails,
};

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import Table from "../../../common/core/Table";

/** ========== SUB COMPONENT ================ */
import ProductSelectionContainer from "../../categoryManagement/containers/ProductSelectionContainer";

/** ========== SUB COMPONENT ================ */
import ProductForm from "./EditForm";

/** ===== IMAGE ICONS =========== */
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import EditIcon from "../../../common/assets/images/svg/edit.svg";

/** ======== MODULE STYLES ========== */
import styles from "../css/EditProductOption.module.css";
import validateForm from "../../../common/utils/validateForm";

const EditProductOption = (props) => {
  const {
    selectedCategories,
    categories,
    productType,
    name,
    priceType,
    productValue,
    enableProduct,
    price,
    skuName,
    listPrice,
    quantity,
    onSale,
    images,
    attributes,
    description,
    longDescription,
    startDate, endDate,
    landingPageUrl,
    handleChange,
    handleFormChange,
    onCancel,
    onSubmit,
    onSubmitting,
    skus,
    handleEditSku,
    handleRedirectToModifySku,
    relatedProducts,
    relatedProductDetails,
    retailerExtensions,
    productDetails,
  } = props;
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product-option"]);

  /**
   * This method is used to return image url form array
   * @param {Array} medias
   */
  const getPreviewImage = (medias) => {
    let imageUrl = null;
    if (Array.isArray(medias) && medias[0]) {
      imageUrl = medias[0].mediaThumbnailUrl;
    }
    return imageUrl;
  };

  const methods = validateForm();

  const {
    handleSubmit, register, errors,
  } = methods;
  return (
    <Row className="">
      <Col xs="12">
        {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/*  ====== PAGE COMPONENT HEADER S[0001]============ */}
          <fieldset disabled={onSubmitting}>
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">
                  {t("product-option:title_edit")}
                </span>
              </Col>
              <Col xs="12" md="5" className="d-flex justify-content-end">
                <Button type="button" onClick={onCancel} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                  {t("common:button_cancel")}
                </Button>
                <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                  {t("common:button_save")}
                </Button>
              </Col>
            </Row>
            {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}

            <div className="common-page-card">
              {/*  ====== PRODUCT FORM E[0005]============ */}
              <ProductForm
                /** form data */
                name={name}
                priceType={priceType}
                productValue={productValue}
                price={price}
                enableProduct={enableProduct}
                skuName={skuName}
                listPrice={listPrice}
                quantity={quantity}
                onSale={onSale}
                images={images}
                productType={productType}
                selectedCategories={selectedCategories}
                categories={categories}
                attributes={attributes}
                description={description}
                longDescription={longDescription}
                startDate={startDate}
                endDate={endDate}
                landingPageUrl={landingPageUrl}
                register={register}
                errors={errors}
                /** events */
                handleFormChange={handleFormChange}
                handleChange={handleChange}
              />
              {/*  ====== PRODUCT FORM E[0005]============ */}
            </div>
          </fieldset>
          {/*  ====== SET PATENT PRODUCTS E[0004]============ */}
        </Form>
        {/*  ====== PAGE COMPONENT FORM E[0002]============ */}
      </Col>
    </Row>
  );
};

EditProductOption.defaultProps = {
  retailerExtensions: null,
};

EditProductOption.propTypes = {
  /** form data */
  name: PropTypes.string.isRequired,
  enableProduct: PropTypes.bool.isRequired,
  skuName: PropTypes.string.isRequired,
  listPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSale: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  productType: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
  skus: PropTypes.arrayOf(PropTypes.any).isRequired,
  attributes: PropTypes.objectOf(PropTypes.any).isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  landingPageUrl: PropTypes.string.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  relatedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  relatedProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  retailerExtensions: PropTypes.objectOf(PropTypes.any),
  productDetails: PropTypes.objectOf(PropTypes.any),
  /** events */
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleEditSku: PropTypes.func.isRequired,
  handleRedirectToModifySku: PropTypes.func.isRequired,
  /** form event */
  onSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};


export default EditProductOption;

/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Static Content Editor
 *
 * @author Vishnu Raj
 *
 */

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import styles from "../css/CustomPopups.module.css";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import Button from "../../../common/core/Button";
import ImageSelection from "../../pageCustomization/components/ImageSelection";
import { uploadImage } from "../../../api/pageContentServices";
import StaticContentFrom from "./StaticContentForm";
import { useTranslation } from "react-i18next";
import Form from "../../../common/core/Form";
import errorIcon from "../../../common/assets/images/svg/error.svg";


const StaticContentCreator = (props) => {

  const {
    handleSubmit: handleSave, onSubmitting, handleCancel, formContents, handleChange, validator, handleChangeFile,
    active, handleStatusChange, imageUrl, type, handleRemoveImage,
  } = props;

  const { t } = useTranslation(["staticContents", "common"])

  /** validation methods */
  const { register, errors, setValue, handleSubmit } = validator;

  return (
    <>
      <Form onSubmit={handleSubmit(handleSave)}>
        <fieldset disabled={onSubmitting}>
          <Row className="common-page-card pageHeader">
            <Col xs="12" md="3" lg="3">
              <span className="pageText">
                {t("staticContents:title")}
              </span>
            </Col>
            <Col xs="12" md="4" lg="5" />
            <Col xs="12" md="5" lg="4" className="buttonGroup">
              <Row>
                <Col xs="6">
                  <Button
                    className="buttonCancel"
                    onClick={() => handleCancel()}>
                    {t("staticContents:label_cancel")}
                  </Button>
                </Col>
                <Col xs="6">
                  <Button
                    className="buttonSave bg-color-tertiary-main"
                    type="submit">
                    {t("staticContents:label_save")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="common-page-card">
            <Col xs="12">
              <Row>
                <Col xs="12" className="pt-3">
                  <h5>{t("staticContents:labe_content")}</h5>
                </Col>
              </Row>
              <hr />
              <StaticContentFrom
                formContents={formContents}
                handleChange={handleChange}
                validator={validator}
                register={register}
                errors={errors}
                setValue={setValue}
              />
              <Col xs="12" md="6" sm="5">
                <Col xs="12" md="6" sm="5">
                  <FormGroup>
                    <Row>
                      <Label className="formTextLabel">{t("common:label_status")}</Label>
                      <div
                        className={clsx(
                          "custom-control",
                          "custom-switch",
                          "ml-2 pt-0",
                        )}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="status-new-brand"
                          name="status"
                          checked={active}
                          onChange={() => handleStatusChange(!active)}
                        />
                        <Label className="custom-control-label" htmlFor="status-new-brand" />
                      </div>
                    </Row>
                  </FormGroup>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className={clsx(styles.block4, "common-page-card")}>
            <Col xs="12">
              <Row className={styles.subTitle}>
                <Col xs="6">
                  <span>Media </span>
                </Col>
              </Row>
              <hr />
              <Row className={styles.block3}>
                <Col className="d-flex col-auto justify-content-start align-items-center">
                  <FormGroup>
                    <ImageSelection
                      handleChangeFile={handleChangeFile}
                      imageUrl={imageUrl}
                      type={type}
                      handleRemoveImage={handleRemoveImage}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </>
  );
}

StaticContentCreator.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  formContents: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeFile: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleRemoveImage: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  validator: PropTypes.shape({
    register: PropTypes.func,
    setValue: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default StaticContentCreator;
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import httpApi from "./httpApi";

/**
 * This method is sued to return list categories
 * @returns {Object}
 */
const getRootCategories = async () => {
  const response = await httpApi.get("v2/categories/roots");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get sub categories
 * @param {String} parentCategoryId
 */
const getCategoriesByParenCategoryId = async (parentCategoryId) => {
  const response = await httpApi.get(`v1/categories/${parentCategoryId}/children`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method used to change status of a particular category
 * @param {String} id,
 * @param {Boolean} status
 */
const setStatus = async (id, status) => {
  const response = await httpApi.patch("v1/categories/status", { id, status });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get leaf categories based on query string
 * @param {String} searchTerm
 */
const listLeafCategories = async (searchTerm) => {
  const response = await httpApi.get("v1/categories/leaf", { params: { searchTerm } });
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to return list of all categories
 * @returns {Array}
 */
const listAllCategories = getRootCategories;


/**
 * This method is used to return list of orphan categories
 * @returns {Array}
 */
const listOrphanCategories = async (searchTerm) => {
  const response = await httpApi.get("v1/categories/orphan", { params: { searchTerm } });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get category details for edit and view
 * @param {String} catalogueId
 * @returns {Object}
 */
const getCategoryDetails = async (catalogueId, locale) => {
  const params = { locale };
  const response = await httpApi.get(`v1/categories/id/${catalogueId}`, { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get Search Config
 * @returns {Object}
 */
const getSearchConfigBySort = async (locale) => {
  const response = await httpApi.post("v1/search-config/searchconfiguration", {
    code: "Sort",
    locale,
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This method is add a new category
 * @param {String} catalogueId
 * @param {Boolean} active
 */
const addCategory = async ({
  id, name, startDate, endDate, description, longDescription,
  status, parentCategory, childCategories, type, childProducts,
}) => {
  const response = await httpApi.post("v1/categories", {
    id,
    name,
    startDate,
    endDate,
    description,
    longDescription,
    active: status,
    parentCategory,
    type,
    childCategories,
    childProducts,
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/*
* This method is use to update category
* @param {String} catalogueId
* @param {Boolean} active
*/
const updateCategory = async ({
  id, name, startDate, endDate, description, longDescription, navPosition,
  status, parentCategory, childCategories, type, childProducts,
  bannerImages, medias, featuredProducts, uniqueId, iconUrl,
  defaultSortParam, navigable,
}, locale) => {
  const params = { locale };
  const response = await httpApi.patch("v1/categories", {
    id,
    name,
    startDate,
    endDate,
    description,
    longDescription,
    navPosition,
    active: status,
    parentCategory,
    type,
    childCategories,
    childProducts,
    bannerImages,
    medias,
    iconUrl,
    featuredProducts,
    uniqueId,
    defaultSortParam,
    navigable,
    params
  },{ params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete a leaf category
 * @param {String} catalogueId
 * @returns {Object}
 */
const deleteLeafCategory = async (catalogueId) => {
  const response = await httpApi.delete(`v1/categories/id/${catalogueId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get SeoInformation
 * @param {String} categoryId
 * @param {Object} seoInfo
 * @returns {Object}
 */
const getSeoInformation = async (categoryId,locale) => {
  const params = { locale };
  const response = await httpApi.get(`v1/categories/admin/${categoryId}/seo-information`, { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update SeoInformation
 * @param {String} categoryId
 * @param {Object} seoInfo
 * @returns {Object}
 */
const updateSeoInformation = async (categoryId, seoInfo, locale) => {
  const params = { locale };
  const response = await httpApi.patch(`v1/categories/${categoryId}/seo-information`, seoInfo, { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * Fetch featured products
 */
const getFeaturedProducts = async (categoryId) => {
  const response = await httpApi.get(`v1/categories/featured-products?categoryId=${categoryId || ""}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used for facet Configuration
 *
 * */
const facetConfig = async (data, locale) => {
  const response = await httpApi.post("/v1/search-config/searchconfiguration", { code: data, locale });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to update Facet Configuration
 *
 * */
const updateFacetConfig = async (data) => {
  const response = await httpApi.put("/v1/search-config/searchconfiguration", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * Fetch Categories by category ids
 */
const getCategoryByIds = async (categoryIds) => {
  const response = await httpApi.post("v1/categories/ids/details", categoryIds);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method get all products for search indexing
 *@returns {Array}
 */
const indexCategories = async () => {
  const response = await httpApi.get("v1/categories/index/categories");
  if (response && response.data) {
    return response.data;
  }
  return null;
};


export {
  getRootCategories,
  getCategoriesByParenCategoryId,
  setStatus,
  listLeafCategories,
  listAllCategories,
  getCategoryDetails,
  addCategory,
  listOrphanCategories,
  deleteLeafCategory,
  updateCategory,
  getSeoInformation,
  updateSeoInformation,
  getFeaturedProducts,
  facetConfig,
  updateFacetConfig,
  getSearchConfigBySort,
  getCategoryByIds,
  indexCategories,
};


/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";

/** ======== MODULE STYLES ========== */
import styles from "../css/AddURLRedirects.module.css";

const AddURLRedirects = ({ cancelForm }) => {
  /** local state */
  const [dropdownTypeOpen, setDropdownTypeOpen] = useState(false);

  /**
   *This method is used to toogle redirect type dropdown
   *
   */
  const toggleDropdownRedirectType = () => {
    setDropdownTypeOpen(!dropdownTypeOpen);
  };

  return (
    <>
      <Row>
        <Col xs="12">
          <Form>
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">SEO Settings</span>
              </Col>
              <Col xs="12" md="5" className="d-flex justify-content-end">
                <Button type="button" className={clsx(styles.borderRadius2, styles.btn1, styles.secondaryColor, "text-truncate", "mr-2")} onClick={cancelForm}>
                  CANCEL
                </Button>
                <Button type="button" className={clsx(styles.borderRadius2, styles.btn2, styles.primaryColor, "text-truncate", "mr-1")}>
                  SAVE
                </Button>
              </Col>
            </Row>
            <div className="common-page-card">
              <Row className="common-page-card">
                <Col xs="12">
                  <FormGroup>
                    <Label className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}> Add URL Redirects</Label>
                  </FormGroup>
                </Col>
                <Col xs="12" md="2" className="ml-3">
                  <FormGroup>
                    <Label className="formTextLabel">Redirect From:</Label>
                  </FormGroup>
                </Col>
                <Col xs="12" md="9" className="pb-4">
                  <Input className="formText" />
                </Col>
                <Col xs="12" md="2" className="ml-3">
                  <FormGroup>
                    <Label className="formTextLabel">Redirect To:</Label>
                  </FormGroup>
                </Col>
                <Col xs="12" md="9" className="pb-4">
                  <Input className="formText" />
                </Col>
                <Col xs="12" md="2" className="ml-3">
                  <FormGroup>
                    <Label className="formTextLabel">Redirect Type:</Label>
                  </FormGroup>
                </Col>
                <Col xs="12" md="9" className="pb-4">
                  <Dropdown
                    isOpen={dropdownTypeOpen}
                    toggle={toggleDropdownRedirectType}
                    className={clsx("commonDropDownWrapper")}
                  >
                    <DropdownToggle caret>
                      <span>
                        Select Redirect Type
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdownMenuWrapper">
                      <div
                        onKeyPress={() => { }}
                        role="button"
                        tabIndex="0"
                        className="dropdownMenuItem"
                      >
                        No
                      </div>
                      <div
                        onKeyPress={() => { }}
                        role="button"
                        tabIndex="0"
                        className="dropdownMenuItem"
                      >
                        301
                      </div>
                      <div
                        onKeyPress={() => { }}
                        role="button"
                        tabIndex="0"
                        className="dropdownMenuItem"
                      >
                        302
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </Col>
                <Col xs="12" md="2" className="ml-3">
                  <FormGroup>
                    <Label className="formTextLabel">Description:</Label>
                  </FormGroup>
                </Col>
                <Col xs="12" md="9">
                  <Input className="formText" />
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

AddURLRedirects.propTypes = {
  cancelForm: PropTypes.func.isRequired,
};

export default AddURLRedirects;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 *
*/

import React from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/Select";

/** ========= API SERVICE FUNCTIONS ========= */
const ProductTypeSelector = ({
  types, name, onChange, selectedProductType, disabled,
}) => (<>
  <Select
    className="formText"
    placeholder="Select price type"
    name={name}
    id="category"
    value={selectedProductType}
    onChange={(o) => onChange("priceType", o)}
    options={types}
    disabled={disabled}
  />
  </>
);

ProductTypeSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedProductType: PropTypes.string.isRequired,
};

export default ProductTypeSelector;

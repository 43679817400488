/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Table from "../../../common/core/Table";
import FormGroup from "../../../common/core/FormGroup";

/** ========= SVG IMAGE ICON ============= */
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import toggleOnIcon from "../../../common/assets/images/svg/toggle-on.svg";
import toggleOffIcon from "../../../common/assets/images/svg/toggle-off.svg";

/** ===== API SERVICE FUNCTIONS =========== */
import {
  setDefaultWarranty,
  setDefaultInstallation,
  setDefaultGiftwrap,
} from "../../../api/productManagementServices";

/** ========= UTILS/CONFIG =============== */

/** ========= MODULE STYLES =============== */
import styles from "../css/ProductOptionManagement.module.css";

const ProductOptionManagement = (props) => {
  const {
    warranty,
    installation,
    giftwrap,
    getPageData,
    page,
    hasMoreData,
    editProductOption,
    disableProductOptionWarranty,
    disableProductOptionInstallation,
    disableProductOptionGiftWrap,
    enableGiftWrap,
    setEnableGiftWrap,
    enableWarranty,
    setEnableWarranty,
    enableInstallation,
    setEnableInstallation,
  } = props;
  //   console.log("Props", warranty);
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product-option"]);
  const [isWarrantyOpen, setIsWarrantyOpen] = useState(false);
  const [isInstallationOpen, setIsInstallationOpen] = useState(false);
  const [isGiftWrapOpen, setIsGiftWrapOpen] = useState(false);
  const [selectedWarranty, setSelectedWarranty] = useState("");
  const [selectedInstallation, setSelectedInstallation] = useState("");
  const [selectedGiftWrap, setSelectedGiftWrap] = useState("");

  const toggleDefaultWarranty = (id, e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedWarranty(id);
      setDefaultWarranty(id);
    }
  };
  const toggleDefaultInstallation = (id, e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedInstallation(id);
      setDefaultInstallation(id);
    }
  };
  const toggleDefaultGiftWrap = (id, e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedGiftWrap(id);
      setDefaultGiftwrap(id);
    }
  };

  const toggleWarranty = () => setIsWarrantyOpen(!isWarrantyOpen);
  const toggleInstallation = () => setIsInstallationOpen(!isInstallationOpen);
  const toggleGiftWrap = () => setIsGiftWrapOpen(!isGiftWrapOpen);

  const onInitiateWarranty = (e) => {
    if (!enableWarranty) {
      setEnableWarranty(true);
    } else {
      disableProductOptionWarranty();
      setSelectedWarranty(null);
    }
  }
  const onInitiateInstallation = (e) => {
    if (!enableInstallation) {
      setEnableInstallation(true);
    } else {
      disableProductOptionInstallation();
      setSelectedInstallation(null);
    }
  }
  const onInitiateGiftWrap = (e) => {
    if (!enableGiftWrap) {
      setEnableGiftWrap(true);
    } else {
      disableProductOptionGiftWrap();
      setSelectedGiftWrap(null);
    }
  }

  const getPreviewImage = (medias) => {
    let imageUrl = null;
    if (Array.isArray(medias) && medias[0]) {
      imageUrl = medias[0].mediaThumbnailUrl;
    }
    return imageUrl;
  };

  useEffect(() => {
    const defaultWarranty = warranty.find((item) => item.preferred);
    const defaultInstallation = installation.find((item) => item.preferred);
    const defaultGiftwrap = giftwrap.find((item) => item.preferred);
    if (defaultWarranty) {
      setSelectedWarranty(defaultWarranty.id);
      setEnableWarranty(true);
    }
    if (defaultInstallation) {
      setSelectedInstallation(defaultInstallation.id);
      setEnableInstallation(true);
    }
    if (defaultGiftwrap) {
      setSelectedGiftWrap(defaultGiftwrap.id);
      setEnableGiftWrap(true);
    }
  }, [warranty, installation, giftwrap]);

  return (
    <>
      <Row className="pageHeader  common-page-card">
        <Col xs="12" md="6" lg="5" className="text-truncate">
          <span className="pageText">
            {t("product-option:title_product_option_manage")}
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block2, " common-page-card")}>
        <Col xs="12">
          <Row className="m-0">
            <Col xs="8" className="p-0">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("product-option:sub_title_product_option_manage")}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block3, "pageHeader common-page-card")}>
        <Col>
          <div
            className="d-flex justify-content-between cursor-pointer"
            role="presentation"
          >
            <span className="w-100">
              <span className="pageText w-100">
                <span>Warranty</span>
                <span className="float-right">
                  <span style={{ fontSize: "13px" }}>{t("product-option:default_check_in")}</span>
                  <img
                    src={enableWarranty ? toggleOnIcon : toggleOffIcon}
                    className="maxQuantitySwitch"
                    width="25px"
                    style={{ marginLeft: "6px" }}
                    alt="drag indiactor icon"
                    onClick={(e) => onInitiateWarranty(e)}
                  />
                  <span onClick={toggleWarranty}>
                    {isWarrantyOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
                      </svg>
                    ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                        </svg>
                      )}
                  </span>
                </span>
              </span>
              <div className="mt-3">
                <span>Product Warranty Details</span>
                <span
                  onClick={(o) => o}
                  onKeyDown={() => { }}
                  tabIndex={0}
                  role="button"
                  className="commonPointer float-right"
                >
                  {/* <img
                    className={clsx("commonPointer", styles.icon)}
                    src={EditIcon}
                    alt="Edit"
                  /> */}
                </span>
              </div>

              {isWarrantyOpen && (
                <div className={(styles.product, "mt-3")}>
                  {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
                  <div
                    className={clsx(
                      styles.productBody,
                      "table-responsive",
                      "custom-table"
                    )}
                  >
                    <Table borderless className={styles.productTable}>
                      {/* ========= TABLE HEAD S[0003] ======================= */}
                      <thead>
                        <tr>
                          <th>{t("common:label_default")}</th>
                          <th>{t("common:label_name")}</th>
                          <th>{t("common:label_value")}</th>
                          <th>{t("common:label_price_type")}</th>
                          <th>{t("common:label_price")}</th>
                          <th>{t("common:label_edit")}</th>
                        </tr>
                      </thead>
                      {/* ========= TABLE HEAD S[0003] ======================= */}
                      <tbody>
                        {/* ========= TABLE BODY S[0002] ======================= */}
                        {warranty.length > 0 ? (
                          warranty.map(({ uniqueId, id, name, priceType, rate }) => (
                            <tr key={id} className={styles.productTreeItem}>
                              {/* ========= EACH CATALOGUE ITEM S[0003] ======================= */}
                              <td>
                                <FormGroup check>
                                  <input
                                    type="radio"
                                    className=""
                                    name="radio1"
                                    id={id}
                                    checked={selectedWarranty === id}
                                    disabled={!enableWarranty}
                                    onChange={(e) =>
                                      toggleDefaultWarranty(id, e)
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <span
                                  title={uniqueId}
                                  className={clsx(
                                    styles.productTreeItem,
                                    "text-truncate",
                                    "d-block"
                                  )}
                                >
                                  {uniqueId}
                                </span>
                              </td>
                              <td>{name}</td>
                              <td>{priceType}</td>
                              <td>{rate}</td>
                              <td>
                                <span
                                  onClick={(o) => editProductOption(id, o)}
                                  onKeyDown={() => { }}
                                  tabIndex={0}
                                  role="button"
                                  className="commonPointer"
                                >
                                  <img
                                    className={clsx(
                                      "commonPointer",
                                      styles.icon
                                    )}
                                    src={EditIcon}
                                    alt="Edit"
                                  />
                                </span>
                              </td>
                              {/* ========= EACH CATALOGUE ITEM E[0003] ======================= */}
                            </tr>
                          ))
                        ) : (
                            <tr>
                              <td colSpan={8} align="center" valign="center">
                                {" "}
                              Nothing to show.
                            </td>
                            </tr>
                          )}
                        {/* ========= TABLE BODY E[0002] ======================= */}
                      </tbody>
                    </Table>
                  </div>
                  <div
                    className={clsx(
                      styles.pagination,
                      "p-3",
                      "d-flex",
                      "justify-content-end"
                    )}
                  >
                    <div className="d-flex">
                      <span
                        disabled={page < 1}
                        className={clsx(styles.item, styles.arrowLeft)}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => { }}
                        onClick={(o) => getPageData(-1, o)}
                      >
                        <span />
                      </span>
                      <span className={styles.item} />
                      <span
                        disabled={!hasMoreData}
                        className={clsx(styles.item, styles.arrowRight)}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => { }}
                        onClick={(o) => getPageData(1, o)}
                      >
                        <span />
                      </span>
                    </div>
                  </div>
                  {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
                </div>
              )}
            </span>
          </div>
          {/*


     */}
          {/* <Col xs="12" md="6" lg="5" className="text-truncate">
            <span className="pageText"></span>
          </Col> */}
          {/* </Row>           */}
        </Col>
      </Row>

      <Row className={clsx(styles.block3, "pageHeader common-page-card")}>
        <Col>
          <div
            className="d-flex justify-content-between cursor-pointer"
            role="presentation"
          >
            <span className="w-100">
              <span className="pageText w-100">
                <span>Installation</span>
                <span className="float-right">
                  <span style={{ fontSize: "13px" }}>{t("product-option:default_check_in")}</span>
                  <img
                    src={enableInstallation ? toggleOnIcon : toggleOffIcon}
                    className="maxQuantitySwitch"
                    width="25px"
                    style={{ marginLeft: "6px" }}
                    alt="drag indiactor icon"
                    onClick={(e) => onInitiateInstallation(e)}
                  />
                  <span onClick={toggleInstallation}>
                    {isInstallationOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
                      </svg>
                    ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                        </svg>
                      )}
                  </span>
                </span>
              </span>
              <div className="mt-3">
                <span>Installation</span>
                <span
                  onClick={(o) => o}
                  onKeyDown={() => { }}
                  tabIndex={0}
                  role="button"
                  className="commonPointer float-right"
                >
                  {/* <img
                    className={clsx("commonPointer", styles.icon)}
                    src={EditIcon}
                    alt="Edit"
                  /> */}
                </span>
              </div>

              {isInstallationOpen && (
                <div className={(styles.product, "mt-3")}>
                  {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
                  <div
                    className={clsx(
                      styles.productBody,
                      "table-responsive",
                      "custom-table"
                    )}
                  >
                    <Table borderless className={styles.productTable}>
                      {/* ========= TABLE HEAD S[0003] ======================= */}
                      <thead>
                        <tr>
                          <th>{t("common:label_default")}</th>
                          <th>{t("common:label_name")}</th>
                          <th>{t("common:label_value")}</th>
                          <th>{t("common:label_price_type")}</th>
                          <th>{t("common:label_price")}</th>
                          <th>{t("common:label_edit")}</th>
                        </tr>
                      </thead>
                      {/* ========= TABLE HEAD S[0003] ======================= */}
                      <tbody>
                        {/* ========= TABLE BODY S[0002] ======================= */}
                        {installation.length > 0 ? (
                          installation.map(({ uniqueId, id, name, priceType, rate }) => (
                            <tr key={id} className={styles.productTreeItem}>
                              {/* ========= EACH CATALOGUE ITEM S[0003] ======================= */}
                              <td>
                                <FormGroup check>
                                  <input
                                    type="radio"
                                    className=""
                                    name="radio2"
                                    id={id}
                                    checked={selectedInstallation === id}
                                    disabled={!enableInstallation}
                                    onChange={(e) =>
                                      toggleDefaultInstallation(id, e)
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <span
                                  title={uniqueId}
                                  className={clsx(
                                    styles.productTreeItem,
                                    "text-truncate",
                                    "d-block"
                                  )}
                                >
                                  {uniqueId}
                                </span>
                              </td>
                              <td>{name}</td>
                              <td>{priceType}</td>
                              <td>{rate}</td>
                              <td>
                                <span
                                  onClick={(o) => editProductOption(id, o)}
                                  onKeyDown={() => { }}
                                  tabIndex={0}
                                  role="button"
                                  className="commonPointer"
                                >
                                  <img
                                    className={clsx(
                                      "commonPointer",
                                      styles.icon
                                    )}
                                    src={EditIcon}
                                    alt="Edit"
                                  />
                                </span>
                              </td>
                              {/* ========= EACH CATALOGUE ITEM E[0003] ======================= */}
                            </tr>
                          ))
                        ) : (
                            <tr>
                              <td colSpan={8} align="center" valign="center">
                                {" "}
                              Nothing to show.
                            </td>
                            </tr>
                          )}
                        {/* ========= TABLE BODY E[0002] ======================= */}
                      </tbody>
                    </Table>
                  </div>
                  <div
                    className={clsx(
                      styles.pagination,
                      "p-3",
                      "d-flex",
                      "justify-content-end"
                    )}
                  >
                    <div className="d-flex">
                      <span
                        disabled={page < 1}
                        className={clsx(styles.item, styles.arrowLeft)}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => { }}
                        onClick={(o) => getPageData(-1, o)}
                      >
                        <span />
                      </span>
                      <span className={styles.item} />
                      <span
                        disabled={!hasMoreData}
                        className={clsx(styles.item, styles.arrowRight)}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => { }}
                        onClick={(o) => getPageData(1, o)}
                      >
                        <span />
                      </span>
                    </div>
                  </div>
                  {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
                </div>
              )}
            </span>
          </div>
        </Col>
      </Row>
      <Row className={clsx(styles.block3, "pageHeader common-page-card")}>
        <Col>
          <div
            className="d-flex justify-content-between cursor-pointer"
            role="presentation"
          >
            <span className="w-100">
              <span className="pageText w-100">
                <span>Gift Wrap</span>
                <span className="float-right">
                  <span style={{ fontSize: "13px" }}>{t("product-option:default_check_in")}</span>
                  <img
                    src={enableGiftWrap ? toggleOnIcon : toggleOffIcon}
                    className="maxQuantitySwitch"
                    width="25px"
                    style={{ marginLeft: "6px" }}
                    alt="drag indiactor icon"
                    onClick={(e) => onInitiateGiftWrap(e)}
                  />
                  <span onClick={toggleGiftWrap}>
                    {isGiftWrapOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
                      </svg>
                    ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                        </svg>
                      )}
                  </span>
                </span>
              </span>
              <div className="mt-3">
                <span>Gift wrap</span>
                <span
                  onClick={(o) => o}
                  onKeyDown={() => { }}
                  tabIndex={0}
                  role="button"
                  className="commonPointer float-right"
                >
                  {/* <img
                    className={clsx("commonPointer", styles.icon)}
                    src={EditIcon}
                    alt="Edit"
                  /> */}
                </span>
              </div>

              {isGiftWrapOpen && (
                <div className={(styles.product, "mt-3")}>
                  {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
                  <div
                    className={clsx(
                      styles.productBody,
                      "table-responsive",
                      "custom-table"
                    )}
                  >
                    <Table borderless className={styles.productTable}>
                      {/* ========= TABLE HEAD S[0003] ======================= */}
                      <thead>
                        <tr>
                          <th>{t("common:label_default")}</th>
                          <th>{t("common:label_name")}</th>
                          <th>{t("common:label_value")}</th>
                          <th>{t("common:label_price_type")}</th>
                          <th>{t("common:label_price")}</th>
                          <th>{t("common:label_edit")}</th>
                        </tr>
                      </thead>
                      {/* ========= TABLE HEAD S[0003] ======================= */}
                      <tbody>
                        {/* ========= TABLE BODY S[0002] ======================= */}
                        {giftwrap.length > 0 ? (
                          giftwrap.map(({ uniqueId, id, name, priceType, rate }) => (
                            <tr key={id} className={styles.productTreeItem}>
                              {/* ========= EACH CATALOGUE ITEM S[0003] ======================= */}
                              <td>
                                <FormGroup check>
                                  <input
                                    type="radio"
                                    className=""
                                    name="radio3"
                                    id={id}
                                    checked={selectedGiftWrap === id}
                                    disabled={!enableGiftWrap}
                                    onChange={(e) =>
                                      toggleDefaultGiftWrap(id, e)
                                    }
                                  />
                                </FormGroup>
                              </td>
                              <td>
                                <span
                                  title={uniqueId}
                                  className={clsx(
                                    styles.productTreeItem,
                                    "text-truncate",
                                    "d-block"
                                  )}
                                >
                                  {uniqueId}
                                </span>
                              </td>
                              <td>{name}</td>
                              <td>{priceType}</td>
                              <td>{rate}</td>
                              <td>
                                <span
                                  onClick={(o) => editProductOption(id, o)}
                                  onKeyDown={() => { }}
                                  tabIndex={0}
                                  role="button"
                                  className="commonPointer"
                                >
                                  <img
                                    className={clsx(
                                      "commonPointer",
                                      styles.icon
                                    )}
                                    src={EditIcon}
                                    alt="Edit"
                                  />
                                </span>
                              </td>
                              {/* ========= EACH CATALOGUE ITEM E[0003] ======================= */}
                            </tr>
                          ))
                        ) : (
                            <tr>
                              <td colSpan={8} align="center" valign="center">
                                {" "}
                              Nothing to show.
                            </td>
                            </tr>
                          )}
                        {/* ========= TABLE BODY E[0002] ======================= */}
                      </tbody>
                    </Table>
                  </div>
                  <div
                    className={clsx(
                      styles.pagination,
                      "p-3",
                      "d-flex",
                      "justify-content-end"
                    )}
                  >
                    <div className="d-flex">
                      <span
                        disabled={page < 1}
                        className={clsx(styles.item, styles.arrowLeft)}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => { }}
                        onClick={(o) => getPageData(-1, o)}
                      >
                        <span />
                      </span>
                      <span className={styles.item} />
                      <span
                        disabled={!hasMoreData}
                        className={clsx(styles.item, styles.arrowRight)}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => { }}
                        onClick={(o) => getPageData(1, o)}
                      >
                        <span />
                      </span>
                    </div>
                  </div>
                  {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
                </div>
              )}
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

ProductOptionManagement.propTypes = {
  warranty: PropTypes.arrayOf(PropTypes.any).isRequired,
  installation: PropTypes.arrayOf(PropTypes.any).isRequired,
  giftwrap: PropTypes.arrayOf(PropTypes.any).isRequired,
  changeProductStatus: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  getPageData: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  editProductOption: PropTypes.func.isRequired,
};

export default ProductOptionManagement;

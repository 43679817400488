
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import CustomizationTable from "../../../common/components/Table";

/** ======== MODULE STYLES ========== */
import styles from "../css/URLRedirectsManagement.module.css";

const customRows = [
  {
    label: "Redirect From",
    value: "",
    path: "redirctFrom",
    transform: "",
  },
  {
    label: "Redirect To",
    value: "",
    path: "redirectTo",
    transform: "",
  },
  {
    label: "Redirect Type",
    value: "",
    path: "redirectType",
    transform: "",
  },
];

const customizationData = [
  {
    redirctFrom: "/abtesting",
    redirectTo: "/products/features/abtesting.html",
    redirectType: 303,
  },
  {
    redirctFrom: "/analytics",
    redirectTo: "/products/features/analytics.html",
    redirectType: 303,
  },
  {
    redirctFrom: "/accessibilty",
    redirectTo: "/products/features/accessibilty.html",
    redirectType: "No",
  },
  {
    redirctFrom: "/reports",
    redirectTo: "/products/features/reports.html",
    redirectType: "No",
  },
];

const URLRedirectsManagement = ({ addNewURL }) => (
  <Row>
    <Col xs="12">
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="7" className="text-truncate">
          <span className="pageText">SEO Settings</span>
        </Col>
      </Row>

      <Row className={clsx(styles.block1, "common-page-card")}>
        <Col xs="12" md="7" className="text-truncate">
          <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>URL Redirects</span>
        </Col>
        <Col xs="12" md="5" className={clsx("d-flex justify-content-end", "pb-3")}>
          <Button onClick={addNewURL} color="primary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
            Add URL Redirects
          </Button>
        </Col>
        <Col xs="12">
          <div className="custom-table ">
            <CustomizationTable
              customRows={customRows}
              customizationData={customizationData}
              isDeleteEnable
              isEditEnable
            />
          </div>
          <div className={clsx(styles.pagination, "p-3", "d-flex", "justify-content-end")}>
            <div className="d-flex">
              <span className={clsx(styles.item, styles.arrowLeft)} disabled>
                <span />
              </span>
              <span className={styles.item} active="active"> 1 </span>
              <span className={styles.item}> 2 </span>
              <span className={styles.item}> 3 </span>
              <span className={clsx(styles.item, styles.arrowRight)}>
                <span />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
);

URLRedirectsManagement.propTypes = {
  addNewURL: PropTypes.func.isRequired,
};

export default URLRedirectsManagement;

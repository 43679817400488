/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Customization Table Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CustomizationTable from "../components/CustomizationTable";
import { getPageCustomizationRows, updatePageContent } from "../../../api/pageContentServices";

const CustomizationTableContainer = (props) => {
  const {
    widget,
    widgetType,
    configId,
    handleRedirectToWidgetContentEditor,
    getPageContents,
    imgEnabled,
    categoryId,
    deleteEnabled,
    channel,
    locale,
  } = props;
  const [pageCustomizationRows, setPageCustomizationRows] = useState([]);
  useEffect(() => {
    getPageCustomizationRows().then((response) => {
      setPageCustomizationRows(response);
    });
  }, []);

  const [customizationData, setCustomizationData] = useState([widget.contents]);
  useEffect(() => {
    setCustomizationData(widget.contents);
  }, [widget.contents]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  /**
 * Delete a content
 *
 * @param {*} indexNumber
 */
  const handleDelete = async (indexNumber) => {
    setIsLoading(true);
    setSelectedIndex(indexNumber);
    const contents = customizationData.filter((item, index) => index !== indexNumber);
    await updatePageContent({
      ...widget,
      widgetType,
      configId,
      contents,
      categoryId,
    }, channel, locale).then((response) => {
      if (response && response.success) {
        getPageContents();
        setIsLoading(false);
      }
    });
  };

  /**
   * This method is used to get  handle change status of a custom a page
   *
   * @param {*} value
   */
  const handleChangeStatus = async (status, rowIndex) => {
    const requestData = {
      ...widget,
      id: widget.id,
      widgetType,
      configId,
      contents: customizationData.map((h, l) => (l === rowIndex ? ({ ...h, active: status }) : h)),
      categoryId,
    };
    const response = await updatePageContent(requestData, channel, locale);
    if (response) {
      const { success } = response;
      if (success) {
        getPageContents();
      }
    }
  };

  return (
    <CustomizationTable
      widgetType={widgetType}
      configId={configId}
      handleRedirectToWidgetContentEditor={handleRedirectToWidgetContentEditor}
      handleDelete={handleDelete}
      isLoading={isLoading}
      selectedIndex={selectedIndex}
      pageCustomizationRows={pageCustomizationRows}
      customizationData={customizationData}
      imgEnabled={imgEnabled}
      deleteEnabled={deleteEnabled}
      handleChangeStatus={handleChangeStatus}
      widget={widget}
      locale={locale}
    />
  );
};
CustomizationTableContainer.propTypes = {
  widget: PropTypes.objectOf(PropTypes.any).isRequired,
  widgetType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  handleRedirectToWidgetContentEditor: PropTypes.func.isRequired,
  getPageContents: PropTypes.func.isRequired,
  imgEnabled: PropTypes.bool.isRequired,
  categoryId: PropTypes.string.isRequired,
  deleteEnabled: PropTypes.bool.isRequired,
  channel: PropTypes.string.isRequired,
};
export default CustomizationTableContainer;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import xlsx from "xlsx";

/** ========= CUSTOM COMPONENTS ============== */
import ProductManagement from "../components/ProductManagement";
import ProductManagementModal from "../components/ProductManagementModal";

/** ===== API SERVICE FUNCTIONS =========== */
import { getProducts, setStatus, productStatusBulkUpload } from "../../../api/productManagementServices";
import { getSitePreviewConfigurations, getRunningJobs, replicateCatalogue, getCatalogueReplicationStatus } from "../../../api/sitePreviewConfigurationsServices";

/*  Main container of the ProductManagementContainer */
const ProductManagementContainer = () => {
  const history = useHistory();
  /** state variables */
  const [products, setProducts] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const paginationLimit = 5;

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const [productModalAttributes, setProductModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: ""
  });
  const [loadingFlags, setLoadingFlags] = useState(false);

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, []);

  /**
   * This method used to change product status
   * @param {String} id
   * @param {Boolean} status
   */
  const changeProductStatus = async (status, id) => {
    if (submitting) return;
    setSubmitting(true);
    const response = await setStatus(id);
    if (response && response.success) {
      const newProducts = Array.isArray(products.content) && products.content.length > 0 && products.content.map((product) => (product.id === id
        ? ({ ...product, active: status }) : product));
      setProducts({ ...products, content: newProducts });
      /** should add a status change toast once the toast functionality implemented */
    }
    setSubmitting(false);
  };

  /**
   * This method is used to list data from server
   * @param {String} localSearchTerm
   * @param {Number} newPage
   */
  const getProductListFromServer = (localSearchTerm, newPage = 0) => {
    let searchValue;
    if (localSearchTerm) {
      searchValue = localSearchTerm.trim();
    }
    /** getting list of products when page load */
    getProducts(searchValue, newPage, paginationLimit).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setProducts(data);
        if (data.totalPages > 1) {
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
        setPage(newPage);
      }
    });
  };

  /**
   * This method is used to open new product
   * This method will trigger when pagination button click
   * @param {Number} page
   */
  const getPageData = (direction) => {
    getProductListFromServer(searchTerm, page + direction, paginationLimit);
  };

  /**
   * This method is used to update text in search box
   * @param {Event} event
   */
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) {
      getProductListFromServer(value, 0);
    } else getProductListFromServer("", 0);
  };

  /**
   * This method is used to load edit page
   * @param {String} categoryId
   */
  const addNewProduct = () => {
    history.push("/merchandising/product/new");
  };

  /**
   * This method is used to open edit page
   * @param {String} productId
   */
  const editProduct = (productId) => {
    history.push(`/merchandising/product/edit/${productId}`);
  };

  useEffect(() => {
    /** getting list of products when page load */
    getProductListFromServer();
  }, [paginationLimit]);

  const initializeData = async () => {
    setSearchTerm("");
    setPage(0);
    setHasMoreData(true);
  };

  const onHandleClear = () => {
    initializeData();
  };

  const handlePushToLive = async () => {
    const runningJobsResponse = await getRunningJobs();
    if (runningJobsResponse) {
      const { data: runningJobs } = runningJobsResponse;
      if (!runningJobs || runningJobs.length === 0) {
        setPushToLiveButtonEnabled(false);
        setShowPushToLiveAlert(true);
        replicateCatalogue().then((response) => {
          if (response && response.success) {
            const alertData = {
              type: "success",
              message: "Products pushed to live successfully. Please refresh the search indexes through Administration > Search Index Management, there may be a small delay for reflecting the same in live.",
            };
            setAlert(alertData);
          } else {
            const alertData = {
              type: "danger",
              message: "Something went wrong. Push to live failed",
            };
            setAlert(alertData);
          }
        });
      } else {
        const alertData = {
          type: "warning",
          message: "An Automated batch job is running in background, Please wait and try again later to make your changes live.",
        };
        setAlert(alertData);
      }
    }
    return null;
  };

  const getReplicationStatus = async () => {
    getCatalogueReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last product push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch(error => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  /**
   * This method is used to convert excel to JSON 
   * @param {Object} e ArticleNo
   */
  const readUploadFile = (e) => {
    setLoadingFlags(true);
    e.preventDefault();
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      const fileName = file.name;
      if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".xls")) {
        setLoadingFlags(false);
        setProductModalAttributes({
          isOpen: true,
          content: "Invalid file type. Please upload a XLSX/XLS file",
          header: "Error"
        })
      } else {
        const reader = new FileReader();
        reader.onload = (f) => {
          const data = f.target.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);
          uploadProductStatus(json)
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    }
  }

  const uploadProductStatus = (productData) => {
    productStatusBulkUpload(productData).then((response) => {
      setLoadingFlags(false);
      if (response && response.success) {
        const emptyObj = Object.keys(response.data).length === 0;
        if (emptyObj) {
          setProductModalAttributes({
            isOpen: true,
            content: response.messages,
            header: "Success"
          })
        } else {
          const data = Object.keys(response.data).map((key) =>` ${key}: ${response.data[key]},`)
          setProductModalAttributes({
            isOpen: true,
            content: data,
            header: "Error"
          })
        }
      }
      getProductListFromServer();
    });
  };

  return (
    <>
      <ProductManagement
        submitting={submitting}
        changeProductStatus={changeProductStatus}
        getPageData={getPageData}
        addNewProduct={addNewProduct}
        editProduct={editProduct}
        page={page} // should remove
        hasMoreData={hasMoreData} // should remove
        searchTerm={searchTerm} // product search text
        handleSearchTerm={handleSearchTerm}
        onHandleClear={onHandleClear}
        productData={products}
        message={message}
        pushToLiveEnabled={pushToLiveEnabled}
        previewSiteUrl={previewSiteUrl}
        handlePushToLive={handlePushToLive}
        pushToLiveButtonEnabled={pushToLiveButtonEnabled}
        getReplicationStatus={getReplicationStatus}
        previewEnabled={previewEnabled}
        readUploadFile={readUploadFile}
        loadingFlags={loadingFlags}
      />
      <ProductManagementModal
        isOpen={productModalAttributes.isOpen}
        content={productModalAttributes.content}
        header={productModalAttributes.header}
        toggleClose={() => setProductModalAttributes({
          isOpen: false,
          content: "",
          header: ""
        })}
      />
    </>
  );
};

export default ProductManagementContainer;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 *  Edit page
 *
 * @author Naseef O
 *
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import Form from "../../../common/core/Form";
import FormFeedback from "../../../common/core/FormFeedback";
import FormGroup from "../../../common/core/FormGroup";
import Button from "../../../common/core/Button";
import styles from "../css/EditPage.module.css";
import WYSIWYG from "../../../common/components/WYSIWYG";
import LocaleDropdownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";
import Nav from "../../../common/core/Nav";
import NavItem from "../../../common/core/NavItem";
import NavLink from "../../../common/core/NavLink";
import TabPane from "../../../common/core/TabPane";
import TabContent from "../../../common/core/TabContent";

const EditPage = ({
  pageForm,
  handleChange,
  handleSave,
  handleChangeEditor,
  content,
  setContent,
  handleSubmit,
  register,
  errors,
  handleRedirectToStaticPages,
  localeHandler,
  activeTab,
  toggleTab,
  codeContent,
  setCodeContent,
  handleChangeCodeEditor,

}) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <Form onSubmit={handleSubmit(handleSave)}>
        <Row className="pageHeader common-page-card">
          <Col xs="12" md="7" className="text-truncate">
            <span className="pageText">Edit</span>
          </Col>
          <Col xs="12" md="5" className="d-flex justify-content-end">
            <Button
              type="button"
              onClick={handleRedirectToStaticPages}
              className="buttonCancel"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              className="ml-2 buttonSave bg-color-tertiary-main"
            >
              Save
            </Button>
          </Col>
        </Row>
        <Row className="pageHeader common-page-card">
          <Col xs="12" md="7">
            <LocaleDropdownContainer
              isIndocument={false}
              entityName="pageContent"
              localeHandler={localeHandler}
            />
          </Col>
        </Row>
        <Row className={clsx(styles.block2, "m-0")}>
          <Col xs="12">
            <Row>
              <Col xs="12">
                <FormGroup className="d-flex ">
                  <Label>Status</Label>
                  <div
                    className={clsx("custom-control", "custom-switch", "ml-1")}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="active"
                      name="active"
                      checked={pageForm.active}
                      onChange={(e) => handleChange(e)}
                    />
                    <Label className="custom-control-label" htmlFor="active" />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel" for="exampleEmail">
                    Page name
                  </Label>
                  <Input
                    className="formText"
                    placeholder="Name"
                    name="pageHeader"
                    defaultValue={pageForm.pageHeader}
                    onChange={handleChange}
                    innerRef={register({
                      required: t("common:validation_name"),
                    })}
                    invalid={!!(errors.pageHeader && errors.pageHeader.message)}
                  />
                  <FormFeedback>
                    {errors.pageHeader && errors.pageHeader.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel" for="exampleEmail">
                    URL
                  </Label>
                  <Input
                    className="formText"
                    placeholder="URL"
                    name="url"
                    defaultValue={pageForm.url}
                    onChange={handleChange}
                    innerRef={register({
                      required: t("common:validation_url"),
                    })}
                    invalid={!!(errors.url && errors.url.message)}
                  />
                  <FormFeedback>
                    {errors.url && errors.url.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab("1");
                        setContent(codeContent);
                      }}
                      className={clsx(
                        { active: activeTab === "1" },
                        styles.tabLink,
                        styles.pageSelectionText,
                        "fontsizeMediumBold",
                      )}
                    >
                      Editor
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab("2");
                        setCodeContent(content);
                      }}
                      className={clsx(
                        { active: activeTab === "2" },
                        styles.tabLink,
                        styles.pageSelectionText,
                        "fontsizeMediumBold",
                      )}
                    >
                      Code view
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div className="common-page-card">
                      <WYSIWYG
                        handleChange={handleChangeEditor}
                        content={content}
                        setContent={setContent}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="common-page-card">
                      <Input
                        style={{
                          height: "200px",
                          backgroundColor: " black",
                          color: "white",
                        }}
                        type="textarea"
                        value={codeContent}
                        onChange={(e) => handleChangeCodeEditor(e)}
                      />
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
              <Col xs="12" />
            </Row>
            <Row className="mt-3">
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel" for="exampleEmail">
                    SEO tags
                  </Label>
                  <Input
                    className="formText"
                    placeholder="SEO tags"
                    name="metaKeywords"
                    defaultValue={pageForm.metaKeywords}
                    onChange={handleChange}
                    innerRef={register({
                      required: t("common:validation_seo-tags"),
                    })}
                    invalid={
                      !!(errors.metaKeywords && errors.metaKeywords.message)
                    }
                  />
                  <FormFeedback>
                    {errors.metaKeywords && errors.metaKeywords.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel" for="exampleEmail">
                    SEO description
                  </Label>
                  <Input
                    value={pageForm.metaDescription}
                    className="formText"
                    placeholder="Description"
                    name="metaDescription"
                    defaultValue={pageForm.metaDescription}
                    onChange={handleChange}
                    innerRef={register({
                      required: t("common:validation_meta-desc"),
                    })}
                    invalid={
                      !!(
                        errors.metaDescription && errors.metaDescription.message
                      )
                    }
                  />
                  <FormFeedback>
                    {errors.metaDescription && errors.metaDescription.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

EditPage.propTypes = {
  pageForm: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleChangeEditor: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  setContent: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  handleRedirectToStaticPages: PropTypes.func.isRequired,
  localeHandler: PropTypes.func.isRequired,
  activeTab: PropTypes.bool.isRequired,
  toggleTab: PropTypes.func.isRequired,
  codeContent: PropTypes.string.isRequired,
  setCodeContent: PropTypes.func.isRequired,
  handleChangeCodeEditor: PropTypes.func.isRequired,
};

export default EditPage;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";

/** ========== CUSTOM COMPONENT ================ */
import ImageSelection from "../components/ImageSelection";
import imageUtils from "../../../common/utils/imageUtils";
import commonUtils from "../../../common/utils/commonUtils";
import AlertModal from "../components/AlertModal";

const ImageSelectionContainer = (props) => {
  const {
    images: media, handleChange, single, aspectRatio, imageValidationCriteria,
  } = props;

  const fileSelector = useRef(null);
  /** local states */
  const [modalOpen, setModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [images, setProductImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [modalAttibutes, setModalAttributes] = useState({ isOpen: false, content: "", header: "" });

  useEffect(() => {
    let inMedia = [];
    if (Array.isArray(media)) {
      inMedia = media;
    } else {
      inMedia = media ? [media] : [];
    }
    setProductImages(inMedia);
  }, [media]);

  const handleUploadImage = (newMedia) => {
    const newImages = single ? [] : images;
    if (newMedia) { newImages.push(newMedia); }
    setProductImages(newImages);
    handleChange(single ? newImages[0] : newImages);
  };

  const handleRemoveImage = (item) => {
    const filteredImages = images.filter((image, index) => index !== item);
    setProductImages(filteredImages);
    if (single) { handleChange(filteredImages.length < 1 ? null : filteredImages[0]); } else { handleChange(filteredImages); }
  };
  /**
 * This method is used to handle change file
 *
 * @param {*} event
 */
  const handleChangeFile = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const { maxSize, acceptedFormat } = imageValidationCriteria || {};
      const { typeArray, errorMessage } = commonUtils.getImageFormatDetails(acceptedFormat) || {};
      const isFormatValidationNeeded = commonUtils.isListNotEmpty(typeArray);
      if (isFormatValidationNeeded && !typeArray.includes(file.type)) {
        setModalAttributes({ isOpen: true, content: errorMessage, header: "Note" });
      } else {
        const sizeDetails = commonUtils.getImageSizeDetails(maxSize);
        if (file.size >= sizeDetails.bytes) {
          setModalAttributes({ isOpen: true, content: sizeDetails.errorMessage, header: "Note" });
        } else {
          const url = await imageUtils.readURL(file);
          setImageUrl(url);
          setModalOpen(true);
        }
      }
      /** [fix] no file change trigger when select same file 2nd time in multi select enabled  */
      fileSelector.current.value = null;
    }
  };

  const handleCloseModal = () => {
    setImageUrl("");
    setModalOpen(false);
  };

  return (
    <>
      <ImageSelection
        images={images}
        handleChangeFile={handleChangeFile}
        modalOpen={modalOpen}
        imageUrl={imageUrl}
        setModalOpen={setModalOpen}
        handleUpload={handleUploadImage}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        handleCloseModal={handleCloseModal}
        handleRemoveImage={handleRemoveImage}
        isSingle={single}
        aspectRatio={aspectRatio}
        fileSelector={fileSelector}
        acceptedFormat={(imageValidationCriteria && imageValidationCriteria.acceptedFormat) || ""}
      />
      <AlertModal
        isOpen={modalAttibutes.isOpen}
        content={modalAttibutes.content}
        header={modalAttibutes.header}
        togglClose={() => setModalAttributes({
          isOpen: false,
          content: "",
          header: "",
        })}
      />
    </>
  );
};

ImageSelectionContainer.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any),
  handleChange: PropTypes.func.isRequired,
  single: PropTypes.bool,
  aspectRatio: PropTypes.number,
  imageValidationCriteria: PropTypes.objectOf(PropTypes.any),
};

ImageSelectionContainer.defaultProps = {
  images: [],
  single: true,
  aspectRatio: 1,
  imageValidationCriteria: {},
};

export default ImageSelectionContainer;

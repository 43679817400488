/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Custom Popup 
 *
 * @author Vishnu Raj
 *
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import styles from "../css/CustomPopups.module.css";
import CustomizationTable from "../../../common/components/Table";
import { useTranslation } from "react-i18next";
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import Label from "../../../common/core/Label";

const StaticContent = (props) => {

  const {
    staticConstentList,
    AddNewStaticContent,
    handleStaticContentStatus,
    submitting,
    selectedContentId,
    active,
    editStaticContent,
    removeStaticContent
  } = props;

  const { t } = useTranslation(["common", "staticContents"]);

  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
          submitting && selectedContentId === row.id ? "loading" : "",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.active}
          onChange={(o) => handleStaticContentStatus(!row.active, row, o)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );
    return newStatus;
  };

  const getActions = (row) => (
    <div className={clsx("d-flex", "justify-content-start", styles.actions)}>
      <span
        className="commonPointer mr-2"
        onClick={(o) => editStaticContent(row.id, o)}
        role="button"
        tabIndex={0}
        onKeyPress={() => { }}
      >
        <img
          className={styles.icon}
          src={EditIcon}
          alt="Edit"
        />
      </span>

      {/* ==========>FOR FUTURE USE<==========  */}

      {/* <span
        className="commonPointer"
        onClick={(o) => removeStaticContent(row.code, o)}
        role="button"
        tabIndex={0}
        onKeyPress={() => { }}
      >
        <img
          className={styles.icon}
          src={DeleteIcon}
          alt="Delete"
        />
      </span> */}
    </div>
  );

  return (
    <Row>
      <Col xs="12">
        <Row className="pageHeader common-page-card">
          <Col xs="12" md="5" className="text-truncate">
            <span className="pageText">{t("staticContents:title")}</span>
          </Col>
        </Row>
      </Col>
      <Col xs="12">
        <Row className={clsx(styles.block2, "common-page-card")}>
          <Col className="mb-2" xs="12" sm="12" md="4">
            <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>
              {t("staticContents:label_staticContent_Heading")}
            </span>
          </Col>
          <Col className="mb-2" xs="12" sm>
            <div className={styles.areaActionRight}>
              <Button
                className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary")}
                onClick={AddNewStaticContent}
                disabled={true}>
                {t("staticContents:label_staticContent_new")}

              </Button>
            </div>
          </Col>
        </Row>
        <Row className={clsx(styles.block3, styles.tableWrapper, "common-page-card")}>
          <Col>
            <div className={styles.promotion}>
              <Row>
                <Col xs="12">
                  <div className="custom-table pt-2">
                    <CustomizationTable
                      customRows={[

                        // ==========>FOR FUTURE USE<========== //

                        // {
                        //   label: t("staticContents:label_staticContent_title"),
                        //   path: "content.title",
                        // },
                        // {
                        //   label: t("staticContents:label_staticContent_description"),
                        //   path: "content.description",
                        // },
                        // {
                        //   label: t("staticContents:label_customPopup_type"),
                        //   path: "type",
                        // },
                        {
                          label: t("staticContents:label_staticContent_Code"),
                          path: "code",
                        },
                        {
                          label: t("common:label_status"),
                          path: "render",
                          render: getStatus,
                        },
                        {
                          label: t("common:label_actions"),
                          path: "render",
                          render: getActions,
                        },
                      ]}
                      customizationData={staticConstentList}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

StaticContent.propTypes = {
  handleStaticContentStatus: PropTypes.func.isRequired,
  editStaticContent: PropTypes.func.isRequired,
  removeStaticContent: PropTypes.func.isRequired,
  staticConstentList: PropTypes.arrayOf(PropTypes.any).isRequired,
  AddNewStaticContent: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  selectedContentId: PropTypes.arrayOf(PropTypes.any).isRequired,
  active: PropTypes.bool.isRequired,
}

export default StaticContent;
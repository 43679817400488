/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PagesTable
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import editIcon from "../../../common/assets/images/svg/edit.svg";
import Table from "../../../common/core/Table";
import transformers from "../../../common/utils/transformers";
import Row from "../../../common/core/Row";
import Label from "../../../common/core/Label";
import Button from "../../../common/core/Button";
/** ====================CSS MODULE ====================== */
import styles from "../css/Table.module.css";

const PagesTable = (props) => {
  const {
    customizationData,
    handleRedirectEditPage,
    handleChangeStatus,
    handlePushToLive,
    previewEnabled,
    pushToLiveEnabled,
    handleRedirectPreviewSite,
  } = props;

  /**
     *This method is used to get a value from an item
     *
     * @param {object} row
     * @param {object} rowItemData
     * @returns
     */
  const getItem = (row, rowItemData) => {
    let data = get(row, rowItemData.path);
    if (rowItemData.transform && rowItemData.transform !== "") {
      data = transformers[rowItemData.transform](row, rowItemData.path);
    }
    return data;
  };
  const pageCustomizationRows = [
    {
      label: "Name",
      value: "",
      path: "pageHeader",
    },
    {
      label: "Url",
      value: "",
      path: "url",
    },

  ];
  const { t } = useTranslation(["common"]);

  return (
    <Table borderless>
      <thead>
        <tr>
          {pageCustomizationRows.map((row) => <th>{row.label}</th>)}
          <th>status</th>
          <th>Edit</th>
          {previewEnabled && <th className="pl-4">Preview</th>}
        </tr>
      </thead>
      <tbody>
        {customizationData
          && customizationData.length > 0 ? customizationData.map((rowItem) => (
            <tr>
              {pageCustomizationRows.map((row) => <td width="10%">{getItem(rowItem, row)}</td>)}
              <td width="10%">
                <div
                  className={clsx(
                    "custom-control",
                    "custom-switch",
                    "ml-1",
                  )}
                >
                  <input
                    type="checkbox"
                    checked={rowItem.active}
                    className="custom-control-input"
                    id={`enableProduct-new-product-${rowItem.id}`}
                    onChange={() => handleChangeStatus(rowItem)}
                    name="enableProduct"
                  />
                  <Label className="custom-control-label" htmlFor={`enableProduct-new-product-${rowItem.id}`} />
                </div>
              </td>

              <td width="10%">
                <img
                  src={editIcon}
                  className="commonPointer"
                  width="23px"
                  alt="drag indiactor icon"
                  role="presentation"
                  onClick={() => handleRedirectEditPage(rowItem.id)}
                />
              </td>
              {previewEnabled && (
                <td width="10%" className={styles.liveBtnWrapper}>
                  <Button
                    color="primary"
                    onClick={() => handleRedirectPreviewSite(rowItem.url)}
                    className={clsx(styles.liveBtn, "text-truncate", "btn-tertiary", "button-font-size ")}
                  >
                    {t("common:button_preview")}
                  </Button>
                </td>
              )}
            </tr>
          ))
          : <Row className="justify-content-center">No data</Row>}
      </tbody>

    </Table>
  );
};

PagesTable.propTypes = {
  customizationData: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleRedirectEditPage: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  handleRedirectPreviewSite: PropTypes.func.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
};

export default PagesTable;

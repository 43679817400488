/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Uplaoding Modal
 *
 * @author Vishnu Raj
 *
 */

import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/core/Modal";
import ModalBody from "../../../common/core/ModalBody";
import ModalFooter from "../../../common/core/ModalFooter";
import Spinner from "../../../common/core/Spinner";
import styles from "../css/CustomPopups.module.css";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";

const UploadModal = (props) => {
  const {
    isOpen,
    toggleOpen,
    togglClose,
    content,
  } = props;

  return (
    <>
      <Modal modalClassName="modalWrapper" isOpen={isOpen} toggle={toggleOpen}>
        <Row>
          {
            content.success == true &&
            <Col xs="1">
              <Spinner className={styles.spinnerWrapper} />
            </Col>
          }
          <Col xs="6" className={styles.contentWrapper}>
            <ModalBody>
              {content.contentData}
            </ModalBody>
          </Col>
        </Row>
        <ModalFooter>
          <button type="button" className="primaryButton" onClick={togglClose}>Close</button>
        </ModalFooter>
      </Modal>
    </>
  );
};

UploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  togglClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
};

export default UploadModal;

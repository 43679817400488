/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Vishnu Raj
 */

import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/core/Modal"
import ModalBody from "../../../common/core/ModalBody";
import ModalFooter from "../../../common/core/ModalFooter";
import ModalHeader from "../../../common/core/ModalHeader";

const ProductManagementModal = (props) => {
  const {
    isOpen,
    toggleClose,
    content,
    header
  } = props;

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalHeader>
          {header}
        </ModalHeader>
        <ModalBody>
          {content}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="secondaryButton" onClick={toggleClose}>Ok</button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ProductManagementModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

export default ProductManagementModal;
/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Static Content Editor Container
 *
 * @author Vishnu Raj
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import imageUtils from "../../../common/utils/imageUtils";
import { uploadImage } from "../../../api/pageContentServices";
import StaticContentEditor from "../components/StaticContentEditorView";
import { addStaticContent, getStaticContentById, updateStaticContent } from "../../../api/staticContentService";


/** ========= CUSTOM COMPONENTS ========= */
import validateForm from "../../../common/utils/validateForm";
import UploadModal from "../components/UploadModal";
import AlertModal from "../../../features/promotionsManagement/components/AlertModal";

/** ========= UTIlS ========= */
import constants from "../../../common/utils/constants";

const StaticContentEditorConatiner = () => {
  const validator = validateForm();
  const history = useHistory();
  const { saticContentId } = useParams();

  const [media, setMedia] = useState({
    largeMediaUrl: "",
    defaultMediaUrl: "",
    smallMediaUrl: "",
    mediaThumbnailUrl: "",
    mimeType: ""
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [formContents, setFormContent] = useState({
    title: "",
    name: "",
    code: "",
    description: ""
  });
  const [submitting, setSubmitting] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [content, setContent] = useState({
    contentData: "",
    success: false
  });
  const [active, setActive] = useState(false);
  const [modalAttibutes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
  });
  const [selectedLanguage, setLanguage] = useState(constants.LOCALE);


  const handleChange = (key, e) => {
    setFormContent({ ...formContents, [key]: e.target.value });
  }

  useEffect(() => {
    getStaticContent();
  }, []);

  const getStaticContent = async () => {
    const response = await getStaticContentById(saticContentId, selectedLanguage);
    if (response && response.success && response.data) {
      const { data } = response
      const medias = data.content.medias
      setFormContent({
        title: data.content.title,
        name: data.type,
        code: data.code,
        description: data.content.description
      });
      setActive(data.active);
      const { largeMediaUrl, mimeType } = Array.isArray(medias) && medias.length > 0 ? medias[0] : media;
      setMedia({ ...media, largeMediaUrl, mimeType });
    } else {
      redirectToStaticContentPage();
    }
  }

  const handleSubmit = async () => {
    setSubmitting(true);
    const data = {
      id: saticContentId,
      locale: constants.LOCALE,
      code: formContents.code,
      content: {
        medias: [{
          ...media
        }]
      },
      active: active
    }
    const response = await updateStaticContent(data);
    if (response && response.success) {
      redirectToStaticContentPage();
    } else {
      setModalAttributes({
        isOpen: true,
        content: "Something went wrong.",
        header: "Error",
      });
    }
    setSubmitting(false)
  };

  const redirectToStaticContentPage = () => {
    history.push("/merchandising/static-content");
  };

  /**
  * This method is used to handle change file
  *
  * @param {*} event
  */
  const handleChangeFile = async (event, type) => {
    const file = event.target.files[0];
    if (file && file.size > 5242880) {
      alert("Image size must under 5mb!");
    } else {
      const mimeType = file.type
      const url = await imageUtils.readURL(file);
      handleUploadImage();
      await uploadImage(file).then((response) => {
        if (response && response.success && response.data) {
          const largeMediaUrl = response.data.largeMediaUrl
          setMedia({ ...media, largeMediaUrl, mimeType })
          setContent({
            contentData: "Uploading Completed",
            success: false
          })
        } else {
          setContent({
            contentData: "Something went wrong in the upload!",
            success: false
          })
        }
      });
    }
  };

  /**
   * This method is used to handle upload image
   *
   */
  const handleUploadImage = () => {
    setContent({
      contentData: "Uploading Image...",
      success: true
    })
    setOpenUploadModal(true)
  };

  const cancelConfirm = () => {
    setOpenUploadModal(false);
  };

  const handleStatusChange = (status) => {
    setActive(status)
  }

  /**
  * This method is used to remove an image
  *
  * @param {*} 
  */
  const handleRemoveImg = () => {
    setMedia({ ...media, largeMediaUrl: "", mimeType: "" });
  };

  return (
    <>
      <StaticContentEditor
        handleSubmit={handleSubmit}
        handleCancel={redirectToStaticContentPage}
        handleChange={handleChange}
        handleChangeFile={handleChangeFile}
        modalOpen={modalOpen}
        media={media}
        setModalOpen={setModalOpen}
        handleUpload={handleUploadImage}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        handleRemoveImage={handleRemoveImg}
        status={true}
        validator={validator}
        formContents={formContents}
        submitting={submitting}
        active={active}
        handleStatusChange={handleStatusChange}
      />
      <UploadModal
        isOpen={openUploadModal}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        content={content}
      />
      <AlertModal
        isOpen={modalAttibutes.isOpen}
        content={modalAttibutes.content}
        header={modalAttibutes.header}
        togglClose={() => setModalAttributes({
          isOpen: false,
          content: "",
          header: "",
        })}
      />
    </>
  );
};

export default StaticContentEditorConatiner;
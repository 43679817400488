/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Vishnu Raj
 */
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";
import Form from "../../../common/core/Form";
import validationConstants from "../../../common/utils/validationConstants";

const StaticContentForm = (props) => {
  const { NO_SPACE } = validationConstants
  const { formContents, handleChange, register, errors, isEdit } = props;

  /** internationalization using i18n from common and promotion  */
  const { t } = useTranslation(["common", "staticContents"]);

  return (
    <>
      <Row className={clsx("common-page-card", "pb-1")}>
        <Col xs="6">
          <FormGroup>
            <Label className="formTextLabel">
              {t("staticContents:label_staticContent_Code")}
              <span className="pl-1 text-danger">*</span>
            </Label>
            <Input
              id="code"
              className="formText"
              placeholder={t("staticContents:label_staticContent_Code")}
              name="code"
              disabled={isEdit}
              defaultValue={formContents.code}
              onChange={(e) => handleChange("code", e)}
              innerRef={
                register({
                  required: "Please enter a code",
                  pattern: {
                    value: NO_SPACE,
                    message: "Code shoud not contains space",
                  },
                  minLength: {
                    value: 3,
                    message: "Code length should be minimum of 3 characters",
                  },
                })
              }
              invalid={!!(errors.code && errors.code.message)}
            />
            <FormFeedback>{errors.code && errors.code.message}</FormFeedback>
          </FormGroup>
        </Col>

        {/* ==========>FOR FUTURE USE<========== */}

        {/* <Col xs="6">
          <FormGroup>
            <Label className="formTextLabel">
              {t("staticContents:label_staticContent_title")}
            </Label>
            <Input
              id="title"
              className="formText"
              placeholder={t("staticContents:label_staticContent_title")}
              name="title"
              defaultValue={formContents.title}
              onChange={(e) => handleChange("title", e)}
            />
          </FormGroup>
        </Col> */}
        {/* <Col xs="6">
          <FormGroup>
            <Label className="formTextLabel">
              {t("staticContents:label_customPopup_type")}
              <span className="pl-1 text-danger">*</span>
            </Label>
            <Input
              id="type"
              className="formText"
              placeholder={t("staticContents:label_customPopup_type")}
              name="type"
              defaultValue={formContents.name}
              onChange={(e) => handleChange("type", e)}
              innerRef={
                register({
                  required: "Please enter a type",
                  minLength: {
                    value: 3,
                    message: "Type must have min length of 3",
                  },
                })
              }
              invalid={!!(errors.type && errors.type.message)}
            />
            <FormFeedback>{errors.type && errors.type.message}</FormFeedback>
          </FormGroup>
        </Col> */}
        {/* <Col xs="6">
          <FormGroup>
            <Label className="formTextLabel">
              {t("staticContents:label_staticContent_description")}
            </Label>
            <Input
              id="description"
              className="formText"
              placeholder={t("staticContents:label_staticContent_description")}
              name="description"
              defaultValue={formContents.description}
              onChange={(e) => handleChange("description", e)}
            />
          </FormGroup>
        </Col> */}
      </Row>
    </>
  );
};
StaticContentForm.defaultpropd = {
  isEdit: false
}
StaticContentForm.propTypes = {
  formContents: PropTypes.arrayOf(PropTypes.any).isRequired,
  // methods
  handleChange: PropTypes.func.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  isEdit: PropTypes.bool
}

export default StaticContentForm;
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";

/** ========= CUSTOM COMPONENTS ============== */
import ProductOptionManagement from "../components/ProductOptionManagement";
import constants from "../../../common/utils/constants";

/** ===== API SERVICE FUNCTIONS =========== */
import {
  getWarrantyProductOptions,
  setStatus,
  getIntallationProductOptions,
  getGiftwrapProductOptions,
  deactivateProductOptionByCode,
} from "../../../api/productManagementServices";
import { constant } from "lodash";

/*  Main container of the ProductManagementContainer */
const ProductOptionManagementContainer = () => {
  const history = useHistory();
  /** state variables */
  const [warranty, setWarranty] = useState([]);
  const [installation, setInstallation] = useState([]);
  const [giftwrap, setGiftwrap] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const paginationLimit = 5;
  const [enableWarranty, setEnableWarranty] = useState(false);
  const [enableInstallation, setEnableInstallation] = useState(false);
  const [enableGiftWrap, setEnableGiftWrap] = useState(false);

  /**
   * This method used to change product status
   * @param {String} id
   * @param {Boolean} status
   */
  const changeProductStatus = async (status, id) => {
    if (submitting) return;
    setSubmitting(true);
    const response = await setStatus(id);
    if (response && response.success) {
      const newProducts = warranty.map((product) =>
        product.id === id ? { ...product, active: status } : product
      );
      setWarranty(newProducts);
      /** should add a status change toast once the toast functionality implemented */
    }
    setSubmitting(false);
  };
  /**
   * This method is used to list data from server
   * @param {String} localSearchTerm
   * @param {Number} newPage
   */
  const getProductListFromServer = (localSearchTerm, newPage = 0) => {
    let searchValue;
    if (localSearchTerm) {
      searchValue = localSearchTerm.trim();
    }
    /** getting list of productOptions when page load */
    getWarrantyProductOptions().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          setWarranty(data);
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
        setPage(newPage);
      }
    });
    getIntallationProductOptions().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          setInstallation(data);
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
        setPage(newPage);
      }
    });
    getGiftwrapProductOptions().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          setGiftwrap(data);
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
        setPage(newPage);
      }
    });
  };

  /**
   * This method is used to open new product
   * This method will trigger when pagination button click
   * @param {Number} page
   */
  const getPageData = (direction) => {
    getProductListFromServer(searchTerm, page + direction, paginationLimit);
  };

  /**
   * This method is used to update text in search box
   * @param {Event} event
   */
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) {
      getProductListFromServer(value, 0);
    } else getProductListFromServer("", 0);
  };

  /**
   * This method is used to open edit page
   * @param {String} productOptionId
   */
  const editProductOption = (productOptionId) => {
    history.push(`/merchandising/product-option/edit/${productOptionId}`);
  };

  useEffect(() => {
    /** getting list of productOptions when page load */
    getProductListFromServer();
  }, [paginationLimit, searchTerm]);

  const initializeData = async () => {
    setSearchTerm("");
    setPage(0);
    setHasMoreData(true);
  };

  const onHandleClear = () => {
    initializeData();
  };

  const disableProductOptionWarranty = async () => {
    const code = constants.PRODUCT_OPTIONS.WARRANTY;
    const response = await deactivateProductOptionByCode(code);
    if (response && response.success) {
      setEnableWarranty(false);
    }
  };

  const disableProductOptionInstallation = async () => {
    const code = constants.PRODUCT_OPTIONS.INSTALLATION;
    const response = await deactivateProductOptionByCode(code);
    if (response && response.success) {
      setEnableInstallation(false);
    }
  };

  const disableProductOptionGiftWrap = async () => {
    const code = constants.PRODUCT_OPTIONS.GIFTWRAP;
    const response = await deactivateProductOptionByCode(code);
    if (response && response.success) {
      setEnableGiftWrap(false);
    }
  };

  return (
    <ProductOptionManagement
      warranty={warranty}
      installation={installation}
      giftwrap={giftwrap}
      submitting={submitting}
      changeProductStatus={changeProductStatus}
      getPageData={getPageData}
      editProductOption={editProductOption}
      page={page} // should remove
      hasMoreData={hasMoreData} // should remove
      searchTerm={searchTerm} // product search text
      handleSearchTerm={handleSearchTerm}
      onHandleClear={onHandleClear}
      disableProductOptionWarranty={disableProductOptionWarranty}
      enableWarranty={enableWarranty}
      setEnableWarranty={setEnableWarranty}
      disableProductOptionInstallation={disableProductOptionInstallation}
      enableInstallation={enableInstallation}
      setEnableInstallation={setEnableInstallation}
      disableProductOptionGiftWrap={disableProductOptionGiftWrap}
      enableGiftWrap={enableGiftWrap}
      setEnableGiftWrap={setEnableGiftWrap}
    />
  );
};

export default ProductOptionManagementContainer;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */
import httpApi from "./httpApi";

/**
 * This method is used to create new Store
 * @param {Object} data
 * @returns {Object}
 */
const addStore = async (data) => {
  const response = await httpApi.post("v1/stores", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update Store
 * @param {Object} data
 */
const updateStore = async (data, locale) => {
  const params = { locale };
  const response = await httpApi.patch("v1/stores", data, { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get Store list
 * @returns {Array}
 */
const getStores = async (data) => {
  const response = await httpApi.get("v1/stores", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get Store Details by id
 * @param  storeId
 */

const getStoreById = async (storeId, locale) => {
  const params = { locale };
  const response = await httpApi.get(`v1/stores/id/${storeId}`, { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete a Store
 * @param {String} id
 */
const deleteStoreById = async (id) => {
  const response = await httpApi.delete(`v1/stores/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getStoreByStoreId = async (storeId) => {
  const response = await httpApi.get(`v1/stores/storeId/${storeId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

// TODO
// Remove the default value UAE after implementing country selecting dropdown
const getCities = async (country) => {
  const response = await httpApi.get("v1/geo-loc-content/cities", { params: { country } });
  return response;
};


/** This method is used to change status in store
 * @param { String } storeId
 */
const setShowInStorefrontStatus = async (storeId) => {
  const response = await httpApi.patch(`v1/stores/${storeId}/store-front/status`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
export {
  addStore,
  updateStore,
  getStores,
  getStoreById,
  deleteStoreById,
  getStoreByStoreId,
  getCities,
  setShowInStorefrontStatus,
};

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Meshal Chalil
 */
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/InventoryReport.module.css";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Select from "../../../common/components/Select";
import Button from "../../../common/core/Button";
import CustomizationTable from "../../../common/components/Table";
import RefreshIcon from "../../../common/assets/images/svg/refresh.svg";

const customRows = [
  {
    label: "Status",
    value: "status",
    path: "status",
    transform: "",
  },
  {
    label: "Start Date",
    value: "startDate",
    path: "startDate",
    transform: "",
    type: "date",
  },
  {
    label: "End Date",
    value: "endDate",
    path: "endDate",
    transform: "",
    type: "date",
  },
  {
    label: "Actions",
    value: "",
    path: "render",
    render: (row) => {
      if (row && row.attributes && row.attributes.exportedFileUrl) {
        return (
          <a
            href={row.attributes.exportedFileUrl}
            className="btn btn-sm btn-info text-white"
          >
            Download
          </a>
        );
      }
      return (
        <button
          type="button"
          className="btn btn-sm btn-info text-white"
          disabled
        >
          Download
        </button>
      );
    },
    transform: "",
  },
];

const InventoryReport = ({
  storeId,
  storeList,
  onHandleStoreChange,
  exportInventory,
  jobHistoryData,
  isExporting,
  refreshPage
}) => {
  const { t } = useTranslation(["common", "inventory-report"]);

  return (
    <>
      {" "}
      {
        isExporting && (
          <div className={clsx(styles.alertPopup)} >
            <div className={clsx(styles.alertMsg)} >
              <h5> {t("inventory-report:inventory_export_activated_alert1")}</h5>
              <h6> {t("inventory-report:inventory_export_activated_alert2")}</h6>
            </div>
          </div>
        )
      }
      <Row className={clsx(styles.block1, "common-page-card pageHeader")}>
        <Col xs="8">
          <span className="pageText">
            {" "}
            {t("inventory-report:inventory_report")}
          </span>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12" className="p-0">
          <Row className="m-0 ">
            <Col xs="12" md="6" className="p-3">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("inventory-report:store_id")}
                </Label>
                <Select
                  className="formTextLabel"
                  placeholder="Select Store Id"
                  name="status"
                  value={storeId}
                  onChange={(e) => onHandleStoreChange(e)}
                  options={[
                    ...[
                      {
                        value: "",
                        label: "Select Store Id",
                      },
                    ],
                    ...(Array.isArray(storeList) ? storeList : []),
                  ]}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="5" className="p-0">
              <FormGroup className="export-button">
                <Button
                  style={{ float: "right", marginTop: "10%" }}
                  onClick={exportInventory}
                  className={clsx(
                    styles.borderRadius2,
                    "text-uppercase",
                    "text-truncate",
                    "btn-tertiary",
                    "button-font-size"
                  )}
                >
                  {t("inventory-report:export")}
                </Button>
              </FormGroup>
            </Col>
            <Col xs="12" md="1" className="p-0">
              <FormGroup className="export-button">
                <span
                  onClick={refreshPage}
                  className="commonPointer"
                  role="presentation"
                  style={{ float: "right", marginTop: "54%" }}
                >
                  <img
                    width="35"
                    src={RefreshIcon}
                    alt="search category"
                    className={styles.icon}
                    title="Refresh"
                  />
                </span>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12">
          <div className="custom-table">
            <CustomizationTable
              customRows={customRows}
              customizationData={jobHistoryData}
              isStatusEnable={false}
              isDeleteEnable={false}
              isEditEnable={false}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default InventoryReport;
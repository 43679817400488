/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * SEOInfoEditor
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import Button from "../../../common/core/Button";
import styles from "../css/WidgetContentEditor.module.css";


const WidgetContentEditor = (props) => {
  const {
    handleChange,
    seoInfoForm,
    handleRedirectToPageCustomization,
    handleSubmit,
  } = props;

  return (
    < >
      <Row className="common-page-card pageHeader">
        <Col xs="12" md="3" lg="3">
          <span className="pageText">
            SEO Information
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
        <Col xs="12" md="5" lg="4" className="buttonGroup">
          <Row>
            <Col xs="6">
              <Button
                className="buttonCancel"
                onClick={() => handleRedirectToPageCustomization()}
              >
                Cancel
              </Button>
            </Col>
            <Col xs="6">
              <Button
                className="buttonSave bg-color-tertiary-main"
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="common-page-card">
        <Col xs="12">
          <Row>
            <Col xs="12" className="pt-3">
              <h5>Edit</h5>
            </Col>
          </Row>
          <Row className={styles.block2}>
            <Col xs="12">
              <Row>
                <Col md="8">
                  <Row>
                    <Col md="6" className="p-3">
                      <FormGroup>
                        <Label className="formTextLabel" for="exampleEmail">Page Title</Label>
                        <Input
                          value={seoInfoForm.description}
                          className="formText"
                          placeholder="Title"
                          onChange={(e) => handleChange("description", e)}
                        />
                        <FormFeedback>You will not be able to see this</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="6" className="p-3">
                      <FormGroup>
                        <Label className="formTextLabel" for="exampleEmail">Meta Keywords</Label>
                        <Input
                          value={seoInfoForm.metaKeywords}
                          className="formText"
                          placeholder="Meta Keywords"
                          onChange={(e) => handleChange("metaKeywords", e)}
                        />
                        <FormFeedback>You will not be able to see this</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="p-3">
                      <FormGroup>
                        <Label className="formTextLabel" for="exampleEmail">Meta description</Label>
                        <Input
                          value={seoInfoForm.longDescription}
                          className="formText"
                          placeholder="Meta description"
                          onChange={(e) => handleChange("longDescription", e)}
                        />
                        <FormFeedback>You will not be able to see this</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};


WidgetContentEditor.propTypes = {
  handleChange: PropTypes.func.isRequired,
  seoInfoForm: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleRedirectToPageCustomization: PropTypes.func.isRequired,
};

export default WidgetContentEditor;

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import httpApi from "./httpApi";

/**
 * This method is used to to return product types from server
 * @returns {Object}
 */
const getProductTypes = async () => ({
  success: true,
  data: ["Apparel"],
});

/**
 * This method is usd to get product attribute by product type
 * @param {String} productType
 */
const getVariantAttributes = async (productType) => {
  if (productType) {
    return ({
      success: true,
      data: [
        {
          key: "color",
          values: ["Red", "Blue", "Green", "black"],
        },
        {
          key: "size",
          values: ["XS", "S", "M", "L", "XL"],
        },
      ],
    });
  }
  return {
    success: false,
  };
};

/**
 * This method is used to return product list
 * @returns {Array}
 */
const getProducts = async (searchTerm = "", page = 0, size = 10) => {
  const response = await httpApi.get("v1/products/admin/search", { params: { searchTerm, page, size } });
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This method is used to return product list
 * @returns {Array}
 */
const getProductsByCategoryId = async (categoryId, searchTerm = "", page = 0, size = 10) => {
  const response = await httpApi.get(`v1/products/categoryId/${categoryId}/search`, { params: { searchTerm, page, size } });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to return product list
 * @returns {Array}
 */
const getProductsByCategory = async (categoryId, page = 0, size = 10) => {
  const response = await httpApi.get(`v1/products/categoryId/${categoryId}`, { params: { page, size } });
  if (response && response.data) {
    return response.data;
  }
  return null;
};


/**
 * This method is used to get single product list by product id
 * @param {String} productId
 * @returns {Object}
 */
const getProduct = async (productId, locale) => {
  const params = { locale };
  const response = await httpApi.get(`v1/products/admin/id/${productId}`, { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method used to change status of a particular category
 * @param {String} productId
 */
const setStatus = async (productId) => {
  const response = await httpApi.patch(`v1/products/${productId}/status`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to add product
 * @param {Object} data
 */
const addProduct = async (data) => {
  const response = await httpApi.post("v1/products", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update product details
 * @param {Object} data
 */
const updateProduct = async (data, locale) => {
  if (locale === "") {
    locale = "en_AE";
  }
  const params = { locale };
  const response = await httpApi.patch("v1/products", data, { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method get all products for search indexing
 *@returns {Array}
 */
const bulkIndexProducts = async () => {
  const response = await httpApi.get("v1/products/search-index/all");
  if (response && response.data) {
    return response.data;
  }
  return null;
};


/**
 * This method is used to upload product image
 *
 * @param {*} image
 * @returns
 */
const uploadProductImage = async (image) => {
  const formData = new FormData();
  formData.append("image", image);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data; charset=utf-8;",
    },
  };
  const response = await httpApi.post("/v1/products/image", formData, config);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to return product list
 * @returns {Array}
 */
const getRelatedProducts = async (productId) => {
  const response = await httpApi.get(`v1/products/admin/${productId}/related-products`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This method is used to return product list
 * @returns {Array}
 */
const getProductByIds = async (productIds) => {
  const response = await httpApi.post("v1/products/product-ids", productIds);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get product option list
 * @returns {Array}
 */
const getWarrantyProductOptions = async () => {
  const response = await httpApi.get("v1/product-options/warranty");
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This method is used to get product option list
 * @returns {Array}
 */
const getIntallationProductOptions = async () => {
  const response = await httpApi.get("v1/product-options/installation");
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This method is used to get product option list
 * @returns {Array}
 */
const getGiftwrapProductOptions = async () => {
  const response = await httpApi.get("v1/product-options/giftwrap");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get product option by product id
 * @param {String} productId
 * @returns {Object}
 */
const getProductOptionById = async (productId) => {
  const response = await httpApi.get(`v1/product-options/id/${productId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to to get product option types from server
 * @returns {Object}
 */
const getProductOptionTypes = async () => ({
  success: true,
  data: [{ value: "PercentagePrice", label: "Percentage price" },
  { value: "FlatPrice", label: "Flat Price" }],
});

/**
 * This method is used to update warranty product details
 * @param {Object} data
 */
const updateWarrantyProductOption = async (data) => {
  const response = await httpApi.patch("v1/product-options", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to set default product option
 * @param {Object} data
 */
const setDefaultWarranty = async (id) => {
  const response = await httpApi.patch(`v1/product-options/default/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This method is used to set default product option
 * @param {Object} data
 */
const setDefaultInstallation = async (id) => {
  const response = await httpApi.patch(`v1/product-options/default/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This method is used to set default product option
 * @param {Object} data
 */
const setDefaultGiftwrap = async (id) => {
  const response = await httpApi.patch(`v1/product-options/default/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};


/**
 * This method is used to set default product option
 * @param {Object} data
 */
const setDefaultProductOption = async (id) => {
  const response = await httpApi.patch(`v1/product-options/default/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get product option list
 * @returns {Array}
 */
const getProductOptions = async () => {
  const response = await httpApi.get("v1/product-options/");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to deactivate product option by code
 * @returns {Array}
 */
const deactivateProductOptionByCode = async (code) => {
  const response = await httpApi.patch(`v1/product-options/${code}/disable-preferred`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};


/**
 * This method is used to get product for promotion
 * @returns {Array}
 */
const getProductForPromotion = async (searchTerms) => {
  const response = await httpApi.post("v1/products/promotion-search", searchTerms);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to bulk upload product status
 * @returns {Array}
 */
const productStatusBulkUpload = async (productDetails) => {
  const response = await httpApi.patch("v1/products/status/bulk", productDetails);
  if (response && response.data) {
    return response.data;
  }
  return null;
}

export {
  getProducts,
  getProduct,
  setStatus,
  bulkIndexProducts,
  getProductTypes,
  getVariantAttributes,
  addProduct,
  updateProduct,
  uploadProductImage,
  getRelatedProducts,
  getProductByIds,
  getProductsByCategoryId,
  getWarrantyProductOptions,
  getProductOptionById,
  getProductOptionTypes,
  updateWarrantyProductOption,
  getProductsByCategory,
  setDefaultWarranty,
  setDefaultInstallation,
  setDefaultGiftwrap,
  getIntallationProductOptions,
  getGiftwrapProductOptions,
  getProductOptions,
  deactivateProductOptionByCode,
  getProductForPromotion,
  productStatusBulkUpload
};
